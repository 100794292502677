import React, { useState, useEffect,useContext } from "react";
import { Link, Outlet, useLocation, useRoutes } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import { HubConnection,IHttpConnectionOptions,IRetryPolicy,LogLevel, HubConnectionBuilder } from "@microsoft/signalr";
import { config } from '../../../environments/environment'
import moment from "moment";
import UserContext from '../../../context/UserContext';
import { NotificationService } from "../../../services/NotificationService";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "./sub-components/StatusPill";
import { ViewMessage } from "./sub-components/ViewMessage";
const Notifications = () => {
  const user = useContext(UserContext);
  const [addressList, setAddressList] = useState([]);
  const [connection, setConnection] = useState();
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);


  var options = { withCredentials: false }
		var retyPolicy = {
			nextRetryDelayInMilliseconds: () => 5000
		}
	

  const getUserNotifications = async () => {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl(`${config.url.NOTIFICATION_API_URL_FOR_SIGNALR}`,options)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect(retyPolicy)
        .build();

      // connection.on("UnReadNotificationForUser", (messages) => {
      //   console.log(messages)
      //   setMessages(messages);
      // });

      connection.on("NotificationForUser", (messages) => {
        console.log(messages)
        setMessages(messages);
      });
      

      

      connection.onclose(e => {
        setConnection();
        setMessages([]);
     
      });

      await connection.start();
      // await connection.invoke("GetUnreadMessagesForUser",user.userData.userId);
      await connection.invoke("GetAllMessagesForUser",user.userData.userId);
      setConnection(connection);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getUserNotifications(); 
}, []);

const notificationColumns = [
  {
    name: "Title",
    selector: (row) => row.messageTitle,
  },
  {
    name: "Message",
    // selector: (row) => row.messageText,
    selector: (row) => (
      <ViewMessage message={row} messages={messages} setMessages={setMessages}/>
      
    ),
  },
  {
    name: "Status",
    selector: (row) => row.isRead?<ActivePill >Read</ActivePill>:<InactivePill>Unread</InactivePill>,
  },
  {
    name: "Date",
    selector: (row) => moment(row.dateSent).format('yyyy-MM-DD'),
  },
  

];

const [notifications,setNotifications] = useState([])
useEffect(() => {
  handleNotifications();
}, []);
const [pending, setPending] = useState(true);
const handleNotifications = () => {
  NotificationService.GetUserNotifications(user.userData.userId).then((res) => {
    console.log(res)
    setNotifications(...notifications, res);
    setPending(false);
    // console.log(concessions)
    
  });
};
const [search,setSearch] = useState("");
const handleSearch = (event) => {
  setSearch(event.target.value); 
};

useEffect(() => {
  console.log(search)
}, [search]);

  return (
    <>
     <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-lime-700">
        Notifications
      </h1>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={notificationColumns}
        data={messages}
        pagination
      />
    </div>
    {/* <div class="offcanvas offcanvas-top fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 right-0 border-none h-1/3 max-h-full" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
    <div class="offcanvas-header flex items-center justify-between p-4">
      <h5 class="offcanvas-title mb-0 leading-normal font-semibold" id="offcanvasTopLabel">Offcanvas top</h5>
      <button type="button" class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body flex-grow p-4 overflow-y-auto">
      ...
    </div>
</div> */}
    </>
    
  );
};

export default Notifications;
