import React from "react";
import manCTimber from "../../../../assets/man_cutting_timber.jpg";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
const UserCard = (props) => {
  const navigate = useNavigate();
  const {
    userId,
    firstName = "Issah",
    lastName = "Muniru",
    role = "Admin",
    profileImage = manCTimber,
    imageUrl=null,
    isActive = false,
    userTypeId = 1,
  } = props;

  const viewUser = () => {
    navigate(`/dashboard/users/${userId}`);
  };
  return (
    <div
      className={`bg-white rounded rounded-2xl flex justify-center items-center flex-col py-4 px-3  shadow shadow-sm hover:shadow-lg cursor-pointer `}
      onClick={viewUser}
    >
      {/* image and role section */}
      <div className="h-16 w-16 relative mb-5">
        {imageUrl ? (
          <img
            src={imageUrl}
            alt=""
            className="object-cover rounded rounded-full h-full w-full"
          />
        ) : (
          <img
            src={profileImage}
            alt=""
            className="object-cover rounded rounded-full h-full w-full"
          />
        )}
        {isActive ? (
          <span className="w-full block text-center absolute -bottom-1 uppercase rounded rounded-full bg-[#DEF6D0] text-[#4B971C]/50 font-medium px-2 text-[0.7rem]">
            Active
          </span>
        ) : (
          <span className="w-full block text-center absolute -bottom-1 uppercase rounded rounded-full bg-red-200 text-red-800/50 font-medium px-2 text-[0.7rem]">
            Disabled
          </span>
        )}
      </div>
      {/* Name */}
      <h2 className="font-semibold text-gray-600">
        {firstName} {lastName}
      </h2>
      {/* <h2 className="text-gray-500  font-thin block">{role}</h2> */}
      {userTypeId == 2 ? (
         <h2 className="text-gray-500  font-thin block">Client</h2>
      ) : (
        <h2 className="text-gray-500  font-thin block">Admin</h2>
      )}
    </div>
  );
};

export default UserCard;
