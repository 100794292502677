import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'

class _StockService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
   
    async AddConcession(concessionName: string, concessionSize: string,description: string,registrationDate:string,totalNumberOfTrees: string,address:string,geoLocation:string,permitNumber: string ,permitExpiryDate: string,permitDocument:any): Promise<ProcessResponse> {
         let data = new FormData();
            data.append('permit_doc', permitDocument);
            data.append('concession_name', concessionName);
            data.append('concession_size', concessionSize);
            data.append('description', description);
            data.append('registration_date', registrationDate);
            data.append('num_of_trees', totalNumberOfTrees);
            data.append('address', address);
            data.append('g_location', geoLocation);
            data.append('permit_number', permitNumber);
            data.append('permit_expiry', permitExpiryDate);
            
            try {
                const res = await getAxios(this.baseUrl).post("/Stock/add/concession", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    
                const result = res.data as ApiResponse<any>;
            if (result.isSuccessful) return { Successful: true, message: result.message };

            return { Successful: false, message: result.message ?? "Unable to Register. Please try again" }
            } catch (error) {
                
                return { Successful: false, message: "An error occurred. Please try again later" };
        
        } 
    }




     async AddCompactment(concessionId: number, compartmentName: string,description:string,numberOfTrees:string,size:string): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Stock/add/compartment", { concessionId, compartmentName,description,numberOfTrees,size});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Save Compactment. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async GetAllConcessions(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/all/concessions");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }


    async AddDelieveryAddress(userId: number, location: string, gpsAddress: string, city: string, postalCode: string, countryId: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/add/delivery/address", { userId, location, gpsAddress, city,postalCode,countryId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Address. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateDeliveryAddress(deliveryAddressId: number, location: string, gpsAddress: string, city: string, postalCode: string, countryId: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/update/delivery/address", { deliveryAddressId, location, gpsAddress, city, postalCode, countryId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Address. Please try again" }
        } catch (error) {

            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async GetDeliveryAddresses(id: number): Promise<UserInfo|any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/delivery/addresses/for/user/${id}`);
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }

    async GetDeliveryAddressById(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/delivery/address/by/id/${id}`);
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async GetAllSpecies(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/species");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }
    async GetProductTypes(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/producttypes");
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }
    async GetAllProducts(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/all/products");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }
    async GetAllTreatments(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/treamenttypes");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    async AddNewOrder(newOrder:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/add/order", newOrder);

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message ,data:data.data};

            return { Successful: false, message: data.message ?? "Unable to Add Order. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

   

    async GetOrders(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/orders/for/user/${id}`);
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {

                return this.sortData(response.data.map((delieveryAdd:any)=>{
                    if (delieveryAdd.dateCreated) delieveryAdd.dateCreated = moment(delieveryAdd.dateCreated).format('yyyy-MM-DD');
                    return delieveryAdd;
                }));
                

                // return test;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetOrderDetail(id: number): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/order/by/id/${id}`);
            var response = res.data as ApiResponse<any>;
            if (response.isSuccessful) {
                if (response.data.dateCreated) response.data.dateCreated = moment(response.data.dateCreated).format('yyyy-MM-DD');
                return response.data;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetOrderStatuses(): Promise<UserInfo | any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Order/get/order/statuses`);
            var response = res.data as ApiResponse<any[]>;
            if (response.isSuccessful) {

                return response.data.map((delieveryAdd:any)=>{
                    if (delieveryAdd.dateCreated) delieveryAdd.dateCreated = moment(delieveryAdd.dateCreated).format('yyyy-MM-DD');
                    return delieveryAdd;
                })
                

                // return test;

            }
            return [];
        } catch (error) {
            return null;
        }
    }

    async GetOrderBasic(): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get("/Stock/get/order/basic/components");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }
    async RemoveOrderItem(orderId: number, orderItemId: number): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/remove/orderitem", { orderId, orderItemId });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to remove item. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async UpdateOrder(newOrder:any): Promise<ProcessResponse> {
        try {

            const res = await getAxios(this.baseUrl).post("/Order/update/order", newOrder);

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Update Order. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async SaveContractDocument(image: any,orderId: string, onUploadProgress: any) {
        let data = new FormData();
        data.append(`contract`, image);
        data.append('orderId', orderId);
        
        try {
            const res = await getAxios(this.baseUrl).post("/Order/upload/order/contract", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            const data2 =  res.data as ApiResponse<any>;
            if (data2.isSuccessful) return { Successful: true, message: data2.message };

            return { Successful: false, message: data2.message ?? "Unable to Upload Order Contract. Please try again" }
        } catch (error) {
            
            return null;
        }
    }

    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }

    //  async AddCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/add/company/client/info", { userId,clientId, companyName, email, phoneNumber });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to Add Company Info. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    //  async UpdateCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/update/company/info", { userId,clientId, companyName, email, phoneNumber });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to update Company Info. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async GetUserById(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/by/id/" + id);
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //              this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }



    //   async GetAdminById(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/by/id/" + id);
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //             //  this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }
 
    // async UpdateUserInfo(data: UserInfo) {
    //     try {
    //         const res = await getAxios().post("/User/update/user", data);
    //         const data_res = res.data as ApiResponse<any>;
    //          if (data_res.isSuccessful) return { Successful: true, message: data_res.message };

    //         return { Successful: false, message: data_res.message ?? "Unable to Update. Please try again" }
    //     } catch (error) {
            
    //         return null;
    //     }
    // }



    // async SaveUserImage(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('imageCollection', image);
    //     data.append('userId', userId);
    //     try {
    //         const res = await getAxios().post("/User/upload/user/image", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }

    // async SaveCompanyDocuments(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('docCollection', image);
    //     data.append('userId', userId);
        
    //     try {
    //         const res = await getAxios().post("/User/upload/company/client/documents", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }

    // SetCurrentUser(userData?: any) {
    //     window.localStorage.setItem(this.localStorageKeys.currentUser, JSON.stringify(userData));
    // }

    // async changePassword(userId: number, oldPassword: string, newPassword: string): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/reset/password/on/dashboard", { userId, oldPassword, newPassword});

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable to change password. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async ActivateUser(userId: number): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/activate/user", { userId });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable Activate. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    //  async DeactivateUser(userId: number): Promise<ProcessResponse> {
    //     try {

    //         const res = await getAxios().post("/User/deactivate/user", { userId });

    //         const data = res.data as ApiResponse<any>;
    //         if (data.isSuccessful) return { Successful: true, message: data.message };

    //         return { Successful: false, message: data.message ?? "Unable Deactivate. Please try again" }
    //     } catch (error) {
            
    //         return { Successful: false, message: "An error occurred. Please try again later" };
    //     }
    // }

    // async GetUserStats(id: number): Promise<UserInfo|any> {
    //     try {
    //         const res = await getAxios().get("/User/get/user/counts");
    //         var response = res.data as ApiResponse<UserInfo>;
    //         if (response.isSuccessful) {
    //             // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
    //             //  this.SetCurrentUser(response.data);
    //             return response.data;

    //         }
    //         return null;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }
    // async SaveUserSignature(image:any, userId:string) {
    //     let data = new FormData();
    //     data.append('imageCollection', image);
    //     data.append('userId', userId);
    //     try {
    //         const res = await getAxios().post("/User/upload/user/signature", data, { headers: { 'Content-Type': 'multipart/form-data' } });
    //         return res.data as ApiResponse<any>;
    //     } catch (error) {
            
    //         return null;
    //     }
    // }


}

export const StockService = new _StockService();
