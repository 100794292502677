import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
// import Input from "../../Input";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import RoleForm from "./sub-components/RoleForm";
import { UserService } from "../../../services/UserService";
import {  FiTrash } from "react-icons/fi";
import swal from "sweetalert";
const EditUserRole = () => {
  
  const roleColumns = [
    {
      name: "Role",
      selector: (row) => row.roleName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Privilages",
      selector: (row) => row.actions,
    },
    
    {
      name:'Actions',
      selector:(row)=><div>
         <button
          className="text-gray-500 px-2 py-1 hover:bg-red-700 hover:text-white"
          onClick={()=>deleteAction(row.roleId)}
        >
          <FiTrash />
        </button>
      </div>
    }
  
  ];

  const [pending, setPending] = useState(true);
  const [roles,setRoles] = useState([])
 
  useEffect(() => {
    handleroles();
  }, []);

   

    const handleroles = () => {
      UserService.GetUserById(params.id).then((res) => {
        
        setRoles(...roles, res.roles);
        setPending(false);
        // console.log(concessions)
        
      });
    };


  const params = useParams();
  

  const deleteAction = (roleId)=>{
    swal({
      title: "Are you sure?",
      text: "Previlages will be lost",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        UserService.RevokeUserRole(parseInt(params.id),parseInt(roleId))
          .then((res) => {
            if (res.Successful) {
              toast.success(res.message);
           
              setTimeout(()=>{
                const filteredRoles = roles.filter((role) => role.roleId !== roleId );
                console.log(filteredRoles)
                setRoles(filteredRoles);
              },1000)
             
             
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {});
      }
    });
 }
  return (

          <>
          <div className="w-full flex flex-col mb-6">
            <RoleForm
              showEdit={true}
              userData={params}
              setUserRoles={setRoles}
              userRoles={roles}
            />
          </div>
          
          <div className="w-full">
          
          <DataTable
            className="w-full rounded rounded-lg"
            columns={roleColumns}
             data={roles}
            progressPending={pending}
            pagination
          />
        </div>
          </>
          
  );
};

export default EditUserRole;
