import React, {useContext, useState,useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
// import {
//   ProfileImageSection,
//   SignatureSection,
// } from "../sub-components/DocumentUploads";
import { UserService } from "../../../services/UserService";
import UserContext from "../../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import Select, { OptionProps } from 'react-select';
import { StockService } from "../../../services/StockService";
const AddNewAddress = () => {
 
  
  let history = useNavigate();
  // history(localStorage.getItem('redirectUrl'))
  const user = useContext(UserContext);
  const [addressState, setAddressState] = useState({
    location: "",
    gpsAddress: "",
    postalCode: "",
    city: "",
    country: "",
    countryId:0
  });
  const [selectedOption, setSelectedOption] = useState(null);
    const [countries, setCountries] = useState([]);
    useEffect(() => {
      handleGetCountry();
    }, []);

    const handleGetCountry = () => {
      UserService.GetCountries().then((res) => {
        // let data = Array.from(res);
        let test = res.map((country)=>{
           country.value = country.countryId;
           country.label = country.countryName;
           return country;
        })

        setCountries(test);
        //  console.log(countries)
      });
    };
   
  const handleChange = (e) => {
    const { value, id } = e.target;
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      [id]: value,
    }));
  };

  const handleCountryChange =(e)=> {
    console.log(e)
    setSelectedOption(e);
    addressState.countryId = e.value
    console.log(`Option selected:`, selectedOption);
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedOption)
    const toastId = toast.loading('Adding New Delivery Address');
    
    const AddAddressPromise = StockService.AddDelieveryAddress(
      user.userData.userId,
      addressState.location,
      addressState.gpsAddress,
      addressState.city,
      addressState.postalCode,
      addressState.countryId,
      
      
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        let redirectUrl = localStorage.getItem('redirectUrl');
        
        if(redirectUrl){
           localStorage.removeItem('redirectUrl')
          history(redirectUrl);
        }else{
          history('/dashboard/delivery-address')
        }
        
       
      } else {
        toast.error(res.message);
      }
    });
    
  };
  return (
    <>
       <div className="flex items-center justify-between w-full">
        <h1 className="my-3 text-4xl font-extrabold text-lime-700">Delivery Addresses</h1>
        <div className="flex items-center">
          <Link
            to="add"
            className="rounded rounded-full p-2 shadow-lg bg-[#3e7c17] hover:bg-[#356C14] mr-2"
          >
            <RiAddLine className="text-lg text-white" />
          </Link>

        </div>
      </div> 
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      {/* <ProfileImageSection /> */}
      <h3 className="my-2 text-xl font-medium text-gray-600 ">
        To Add A New Address, Please Fill Out The Form Below.
      </h3>
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">GPS Address</span>
          <Input
            id="gpsAddress"
            value={addressState.gpsAddress}
            onChange={handleChange}
            
            
            type="text"
            placeholder="GPS Address"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Location</span>
          <Input
            id="location"
            value={addressState.location}
            onChange={handleChange}
            
            required
            type="text"
            placeholder="Location"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">City</span>
          <Input
            id="city"
            value={addressState.city}
            onChange={handleChange}
            
            required
            type="text"
            placeholder="City"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Postal Code
          </span>
          <Input
            id="postalCode"
            value={addressState.postalCode}
            onChange={handleChange}
            
            required
            type="text"
            placeholder="Postal Code"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Country
          </span>
          <Select  
                  options={countries}
                  value={selectedOption}
                  
                  defaultValue={selectedOption}
                  onChange={handleCountryChange}
                  menuPlacement="auto" menuPosition="fixed" placeholder="Select Country"/>
        </label>
      </FormGrid>
     
      <button
        type="submit"
        className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Delivery Address
      </button>
    </form>
    </>
    
  );
};

export default AddNewAddress;

export const FormGrid = ({ children }) => {
  return (

     
      <div className="grid w-full grid-cols-1 gap-4 mb-2 sm:w-4/6 sm:grid-cols-2">
      {children}
    </div>
  
    
  );
};
