import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";
import { config } from '../environments/environment'

class _ReportService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    private baseUrl = config.url.STOCK_API_URL;
   

    async GetOrderCounts(userId:number): Promise<any> {
        try {
            const res = await getAxios(this.baseUrl).get(`/Report/get/order/count/for/user/${userId}`);
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');

                return response.data;

            }
            return [];
        } catch (error) {

            return null;
        }
    }

    


}

export const ReportService = new _ReportService();