import React from "react";
import manCTimber from "../../../../assets/man_cutting_timber.jpg";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { RiBuilding4Line, RiCarLine, RiShoppingCartLine } from "react-icons/ri";
const UserCard = (props) => {
  const navigate = useNavigate();
  const {
    orderId,
    contractNo,
    unitPrice,
    totalAmount,
    dateCreated,
    orderNumber,
    numberOfProducts,
    grossAmount,
    vatAmount,
    phoneNumber,
    destinationCountry,
    deliveryAddress,
    orderStatus
  } = props;

  const viewUser = () => {
    navigate(`/dashboard/my-orders/${orderId}`);
  };
  return (
   
    <div onClick={viewUser} className="block bg-white ">
    <div className="inline-flex items-center justify-center px-6 py-3 text-gray-600 border-b border-gray-300">
    {/*  */}
      
      <RiShoppingCartLine className="inline-block ml-2 mr-1"/>
      
      <div className="inline-block ">Order Number: {orderNumber} </div>
    </div>
    <div className="justify-center p-2">
      {/* <h5 className="mb-2 text-xl font-medium text-gray-400">Special title treatment</h5> */}
     
        <ul className="text-gray-600 bg-white justify-left">
          <li className="w-full px-6 py-2 "> <span>Date: {dateCreated}</span></li>
          <li className="w-full px-6 py-2 "><span>Phone Number: {phoneNumber}</span></li>
          <li className="w-full px-6 py-2 "><span>Country: {destinationCountry.countryName}</span></li>
          <li className="w-full px-6 py-2 "><span>Delivery Address: {deliveryAddress?.location||''}</span></li>
          <li className="w-full px-6 py-2 "><span>VAT: GHS {vatAmount}</span></li>
          <li className="w-full px-6 py-2 "><span>Gross Amount: GHS {grossAmount}</span></li>
          <li className="w-full px-6 py-2 "><span>Total Amount: GHS {totalAmount}</span></li>
          <li className="w-full px-6 py-2 "><span>Number of items: {numberOfProducts}</span></li>
          <li className="w-full px-6 py-2 "><span>Order Status:  <span className="text-white  bg-lime-500 px-2 py-1 rounded-md">{orderStatus.orderStatusName}</span> </span></li>
        </ul>
      
      
    </div>
    <div className="px-6 py-3 text-gray-600 border-t border-gray-300">
    <button type="button" className="justify-center w-full   px-6 py-2.5 bg-lime-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-lime-600 hover:shadow-lg focus:bg-lime-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-800 active:shadow-lg transition duration-150 ease-in-out">View Order</button>
    </div>
  </div>
  );
};

export default UserCard;
