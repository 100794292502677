import React, { useMemo } from "react";
import { Link, Outlet, useLocation, useRoutes } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import { orderRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";
import GoBack from "../sub-components/GoBack";
import { FaFileExport } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
const MyOrders = () => {
  const tabPaths = orderRoutes.map(({ path }) => `/dashboard/my-orders/${path}`);
  const { pathname } = useLocation();
  // console.log({ pathname }, { tabPaths });
  const headerComponent = useMemo(
    () => (tabPaths.includes(pathname) ? <DashboardMyOrdersHeader /> : <GoBack />),
    [pathname]
  );
  return (
    <div className="flex flex-col items-start w-full h-full">
      {headerComponent}
      <Outlet />
    </div>
  );
};

export default MyOrders;

const DashboardMyOrdersHeader = () => {
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <h1 className="my-3 text-4xl font-extrabold text-lime-700">My Orders</h1>
        <div className="flex items-center">
          <Link
            to="/dashboard/place-order"
            className="rounded rounded-full p-2 shadow-lg bg-[#3e7c17] hover:bg-[#356C14] mr-2"
          >
            <RiAddLine className="text-lg text-white" />
          </Link>
          {/* <Link to="export" className="p-2">
            <AiOutlineFilePdf className="text-xl text-gray-700" />
          </Link> */}
        </div>
      </div>
      <nav className="flex flex-wrap w-full py-4 mb-3 text-left border-b border-gray-300/25">
        {orderRoutes.map((route, index) => (
          <DashboardTabLink key={index} {...route} />
        ))}
      </nav>
    </>
  );
};
