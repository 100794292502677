import React, { useState } from "react";
import { RiEditLine } from "react-icons/ri";
import Select from "react-select";
import { useEffect } from "react";
import toast from "react-hot-toast";
const TeamMemberForm = ({ defaultValue,disabled,options,setTeamMembers }) => {
  console.log(defaultValue)
  const [value, setValue] = useState([]);
  const pluck = property => element => element[property]
  const handleChange = (value) => {
    setValue(value);
    const teamIds = value.map(pluck('userId')).join(";");
    setTeamMembers(teamIds);
    
  }
 

  return (
    <div className="w-full flex flex-col" >
      <Select
        className="w-full max-w-[30rem]"
        defaultValue={defaultValue}
        value={value}
        isDisabled={disabled}
        options={options}
        onChange={(value) => handleChange(value)}
        isMulti
      />

    </div>
  );
};

export default TeamMemberForm;

// const options = [
//   { value: "ernest", label: "Ernest" },
//   { value: "gaisie", label: "Gaisie"},
// ];
