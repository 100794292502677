import React, { useState, useContext, useEffect } from "react";
import { RiAddCircleFill,RiDeleteBinFill } from "react-icons/ri";
import './ordertable.css';
import { StockService } from "../../../../../services/StockService";
import Select, { OptionProps } from 'react-select';
import NumberFormat from 'react-number-format';
var tableRowIndex = 0;
const Table = (props) => {

   const {
    rowsData,
    deleteTableRows,
    handleChange,
    handleSelectChange,
    totalAmount,
    totalItems,
    addressState,
    handleCommentChange,
    products,
    productTypes,
    species,
    treatmentTypes

  } = props;
  // console.log(addressState)
  console.log(rowsData)
  const [pending, setPending] = useState(true);

  // useEffect(() => {
  //   let count2 = productTypes.find(c=>c.productTypeId === addressState.deliveryAddress.deliveryAddressId);
  //     handleSelectChange(count2);
  //   }, [productTypes]);
  // useEffect(() => {
  //   let count = products.find(c=>c.productId === addressState.destinationCountry.countryId);
  //   handleSelectChange(count);
  //   }, [products]);
 
  // useEffect(() => {
  //   handleProducts();
  // }, []);

  
  // const handleProducts = () => {
  //   StockService.GetAllProducts().then((res) => {
  //     let test = res.map((product)=>{
  //       product.value = product.productId;
  //       product.name = "product";
  //       product.label = product.productName;
  //       return product;
  //    })
  //     setProducts(test);
  //     // setPending(false);
  //     // console.log(concessions)
      
  //   });
  // };


  //   const handleChange = (data,event) => {
  //    console.log(data)
  //    console.log(event)
  //     rowsData[data.index] = data
  //  }
  // const [stateTable,setStateTable] = useState(tableDataInit)
  return (
    <form className="w-full ">
      <table id="customers" className="w-full table-auto ">
        <thead>
          <tr>
            <th>Select Product Type</th>
            <th>Select Product</th>
            <th>Species</th>
            <th>Treatment</th>
            <th>Thickness(mm)</th>
            <th>Width(mm)</th>
            <th>Length(mm)</th>
            
            <th>Qty</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
                  rowsData.map((row, index) => {

                     if(row)
                     console.log(row)
                     return(
                      <tr key={index}   >
                        <td>
                        <Select 
                          id={index}
                          className="ot-s" 
                          options={productTypes}
                          value={row.productType}
                          name="productType"
                          defaultValue={row.productType}
                          onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          menuPlacement="auto" menuPosition="fixed" placeholder="Type"/>
                          {/* <select className="ot-s">
                            <option>Product Type</option>
                          </select> */}
                        </td>
                        <td>
                        <Select 
                        id={index}
                          className="ot-s" 
                          options={products}
                          value={row.product}
                          name="product"
                          defaultValue={row.product}
                          onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          menuPlacement="auto" menuPosition="fixed" placeholder="Product"/>
                        </td>
                        <td>
                        <Select 
                        id={index}
                          className="ot-s" 
                          options={species}
                          value={row.species}
                          name="species"
                          defaultValue={row.species}
                          onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          menuPlacement="auto" menuPosition="fixed" placeholder="Species"/>
                        </td>
                        <td>
                        <Select 
                        id={index}
                          className="ot-s py-3" 
                          options={treatmentTypes}
                          value={row.treatment}
                          name="treatmentType"
                          defaultValue={row.treatment}
                          // onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          onChange={(evnt)=>(handleSelectChange(index, evnt))}
                          menuPlacement="auto" menuPosition="fixed" placeholder="Treatment"/>
                        </td>
                        <td className="ot-input">
                          <input className="text-center w-full mt-3 pt-2 px-1 py-2.5 border border-gray-300 rounded-sm rounded outline-lime-600 outline-offset-2" id='thickness' value={row.thickness||row.tickness} onChange={(evnt)=>(handleChange(index, evnt))} type="text" name="thickness"  />
                        </td>
                        <td className="ot-input">
                          <input value={row.width} onChange={(evnt)=>(handleChange(index, evnt))} type="number" name="width" className="text-center w-full mt-3 pt-2 px-1 py-2.5 border border-gray-300 rounded-sm rounded outline-lime-600 outline-offset-2" />
                        </td>
                        <td className="ot-input">
                          <input value={row.length} onChange={(evnt)=>(handleChange(index, evnt))} type="number" name="length" className="text-center w-full mt-3 pt-2 px-1 py-2.5 border border-gray-300 rounded-sm rounded outline-lime-600 outline-offset-2" />
                        </td>
                        
                        <td className="ot-input">
                          <input value={row.quantity} onChange={(evnt)=>(handleChange(index, evnt))} type="number" name="quantity" className="text-center w-full mt-3 pt-2 px-1 py-2.5 border border-gray-300 rounded-sm rounded outline-lime-600 outline-offset-2" />
                        </td>
                        <td className="ot-input">
                          <input value={row.price} onChange={(evnt)=>(handleChange(index, evnt))} type="number" name="price" className="text-center w-full mt-3 pt-2 px-1 py-2.5 border border-gray-300 rounded-sm rounded outline-lime-600 outline-offset-2" readOnly />
                        </td>
                        <td>
                          <RiDeleteBinFill onClick={()=>deleteTableRows(index)}  style={{ color: '#a12828' }} className="mt-2"/>
                        </td>
                      </tr>
                        // <TableRow key={index} row={row} handleDataChange={handleChange} deleteRow={deleteRow}></TableRow>
                     )
                  })
               }
          
        </tbody>
      </table>
      <div className="flex w-full mt-4 ">
        <textarea rows={4} className="w-full px-2 mb-2 text-gray-500" id="comment"  value={props.addressState.comment} placeholder="Additional Comments" onChange={handleCommentChange} />
      </div>
      {props.addressState.destinationCountry.shortName !== 'GH' && (
        <div className="px-4 summary">
          <div className="no-items">
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{totalItems}</span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount*0.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount * 1.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
        {props.addressState.destinationCountry.shortName === 'GH' && (
        <div className="summary">
          <div className="no-items">
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{totalItems}</span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount*0.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount * 1.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
      
      {/* <div className="sum-submit">
        <input
          type="submit"
          value="SUBMIT"
          style={{ width: '30%', marginLeft: '70%', backgroundColor: '#3E7C17' }}
        />
      </div> */}
    </form>
  );
};

export default Table;
