import {
  RiDashboardFill,
  RiDashboardLine,
  RiSettings5Fill,
  RiSettings5Line,
  RiDatabaseFill,
  RiDatabaseLine,
  RiShoppingCartLine,
  RiShoppingCartFill,
  RiNotificationBadgeFill,
  RiNotificationBadgeLine,
  RiNotification2Line,
  RiNotification2Fill,
  RiShoppingBasketFill,
  RiShoppingBasketLine,
  RiBuilding4Fill,
  RiBuilding4Line

} from "react-icons/ri";
import { HiUsers, HiOutlineUsers } from "react-icons/hi";
import {BsTree} from 'react-icons/bs'
import {GiLogging} from 'react-icons/gi'
import manCTimber from "../assets/man_cutting_timber.jpg";
// sidebar utils
export const dashboardNavigation = [
  {
    name: "Dashboard",
    IconFilled: (props) => <RiDashboardFill {...props} />,
    Icon: (props) => <RiDashboardLine {...props} />,
    path: "main",
  },
  // {
  //   name: "Users",
  //   IconFilled: (props) => <HiUsers {...props} />,
  //   Icon: (props) => <HiOutlineUsers {...props} />,
  //   path: "users",
  // },
  {
    name: "Place Order",
    IconFilled: (props) => <RiShoppingBasketFill {...props} />,
    Icon: (props) => <RiShoppingBasketLine {...props} />,
    path: "place-order",
  },
  {
    name: "Orders",
    IconFilled: (props) => <RiShoppingCartFill {...props} />,
    Icon: (props) => <RiShoppingCartLine {...props} />,
    path: "my-orders",
  },
   {
    name: "Delivery Address",
    IconFilled: (props) => <RiBuilding4Fill {...props} />,
    Icon: (props) => <RiBuilding4Line {...props} />,
    path: "delivery-address",
  },
  {
    name: "Notifications",
    IconFilled: (props) => <  RiNotification2Fill    {...props} />,
    Icon: (props) => <RiNotification2Line {...props} />,
    path: "notifications",
  },
 
 
  // {
  //   name: "Stock Management",
  //   IconFilled: (props) => <RiDatabaseFill {...props} />,
  //   Icon: (props) => <RiDatabaseLine {...props} />,
  //   path: "stock-management",
  // },
  {
    name: "Profile",
    IconFilled: (props) => <RiSettings5Fill {...props} />,
    Icon: (props) => <RiSettings5Line {...props} />,
    path: "profile",
  },
];

// ROUTES FOR THE PROFILE SECTION  IN THE DASHBOARD
export const profileRoutes = [
  {
    name: "User Details",
    path: "personal-details",
  },
  {
    name: "Change Password",
    path: "change-password",
  },
];
export const corporateRoutes = [
  {
    name: "User Details",
    path: "personal-details",
  },
  {
    name: "Business Details",
    path: "company-details",
  },
  {
    name: "Change Password",
    path: "change-password",
  },
];


// ROUTES FOR THE USERS SECTION IN THE DASHBOARD
export const userRoutes = [
  {
    name: "All Users",
    path: "all",
  },
  {
    name: "Active Users",
    path: "active",
  },
  {
    name: "Deactivated Users",
    path: "deactivated",
  },
  {
    name: "Client Users",
    path: "client",
  },
  {
    name: "Internal Users",
    path: "internal",
  },
];

export const userEditRoutes = [
  {
    name: "Edit User Profile Details",
    path: "profile",
  },
  {
    name: "Edit User Address",
    path: "address",
  },
  {
    name: "Edit User Role",
    path: "roles",
  },
  
];

// ROUTES FOR STOCK MANAGEMENT IN THE DASHBOARD
export const stockRoutes = [
  {
    name: "Concession",
    path: "concession",
  },
  {
    name: "Compartment",
    path: "compartment",
  },
  {
    name: "Species",
    path: "species",
  },
  {
    name: "Trees",
    path: "trees",
  },
  {
    name: "Teams",
    path: "teams",
  },
  {
    name: "Products",
    path: "products",
  },
  {
    name: "Product Types",
    path: "product-types",
  },
];

// ROUTES FOR THE USERS SECTION IN THE DASHBOARD
export const orderRoutes = [
  {
    name: "All Orders",
    path: "all",
  },
  {
    name: "New Orders",
    path: "new",
  },
  {
    name: "In Progress Orders",
    path: "active",
  },
  {
    name: "In Transport Orders",
    path: "transport",
  },
  {
    name: "Completed Orders",
    path: "complete",
  },
];




// test users
export const testUsers = [
  {
    id: 1,
    username: "Missah",
    firstName: "Issah",
    lastName: "Muniru",
    email: "issahmuniru@gmail.com",
    phoneNumber: "+23355345679",
    location: "Lakeside",
    address1: "Lakeside Barrier",
    address2: "Lakeside Second",
    role: "Admin",
    profileImage: manCTimber,
    active: false,
  },
  {
    id: 2,
    username: "ErnestGaisie",
    firstName: "Ernest",
    lastName: "Gaisie",
    email: "ernestgaisie@gmail.com",
    phoneNumber: "+23355234566",
    location: "Nungua",
    address1: "Nungua Barrier",
    address2: "Nungua Second",
    role: "Internal User",
    profileImage: manCTimber,
    active: false,
  },
  {
    id: 3,
    username: "BotweIsaac",
    firstName: "Botwe",
    lastName: "Isaac",
    email: "botweisaac@gmail.com",
    phoneNumber: "+23355679905",
    location: "Labadi",
    address1: "Labadi Barrier",
    address2: "Labadi Second",
    role: "Internal User",
    profileImage: manCTimber,
    active: true,
  },
];

// DUMMY ROLES FOR USERS
export const userRoles = {
  CLIENT: "Client User",
  INTERNAL: "Internal User",
  ADMIN: "Admin",
};

export const testConcessions=[
  {
    id:1,
    concessionName: "concession1",
    concessionSize: "10sqm",
    description: "A large concession",
    totalNumberOfTrees: 2000,
    address: "Manet Palms",
    permitNumber: "123456",
    permitExpiryDate: new Date().toISOString(),
    permitDocument: ".docsx",
  },
  {
    id:2,
    concessionName: "concession2",
    concessionSize: "10sqm",
    description: "A large concession",
    totalNumberOfTrees: 2000,
    address: "Manet Palms",
    permitNumber: "123456",
    permitExpiryDate: new Date().toISOString(),
    permitDocument: ".docsx",
  },{
    id:3,
    concessionName: "concession3",
    concessionSize: "10sqm",
    description: "A large concession",
    totalNumberOfTrees: 2000,
    address: "Manet Palms",
    permitNumber: "123456",
    permitExpiryDate: new Date().toISOString(),
    permitDocument: ".docsx",
  },{
    id:4,
    concessionName: "concession4",
    concessionSize: "10sqm",
    description: "A large concession",
    totalNumberOfTrees: 2000,
    address: "Manet Palms",
    permitNumber: "123456",
    permitExpiryDate: new Date().toISOString(),
    permitDocument: ".docsx",
  },{
    id:5,
    concessionName: "concession5",
    concessionSize: "10sqm",
    description: "A large concession",
    totalNumberOfTrees: 2000,
    address: "Manet Palms",
    permitNumber: "123456",
    permitExpiryDate: new Date().toISOString(),
    permitDocument: ".docsx",
  },
]