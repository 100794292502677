import React, { useState, useContext, useEffect } from "react";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import { BsDownload,BsPencilFill } from "react-icons/bs";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import DocumentUpload from "../sub-components/DocumentUploads"; 
import UserContext from "../../../context/UserContext";
import { UserService } from "../../../services/UserService";
const CompanyDetails = () => {
  const user = useContext(UserContext);
 
  const [state, setState] = useState(user.userData.clientFullInfo);
  console.log(state)
  const loadUserProfile = () => {
    UserService.GetUserById(parseInt(user.userData.userId)).then((res) => {
      console.log(res)
      setState(state,res.clientFullInfo)
    });
  };

   useEffect(() => {
    
         loadUserProfile();
       
   }, []);

  
  const id = state.userId;
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
    const toastId = toast.loading("Updating Profile");
const updateProfilePromise = UserService.AddCompanyInfo(
  user.userData.userId,
  user.userData.clientFullInfo.clientId,
  state.companyName,
  state.registrationDate,
  state.commencementDate,
  state.email,
  state.phoneNumber,
).then((res) => {
  toast.dismiss(toastId);
  if (res.Successful) {
    // toast.success(res.message);

    if(state.certificate_of_commencement){
      uploadDocument(state.certificate_of_commencement,"certificate_of_commencement")
     
    }
    if(state.certificate_of_registration){
      setTimeout(()=>{
        uploadDocument(state.certificate_of_registration,"certificate_of_registration")
      },3000)
      
    }
    if(state.logo){
      
      setTimeout(()=>{
        uploadDocument(state.logo,"logo")
      },9000)
    }
    // UserService.GetUserById(state.userId).then(
    //   (ress) => {
    //     window.location.reload();
    //   }
    // );
  } else {
    toast.error(res.message);
  }
});
    // handles the toast loader for the login process
    // toast.promise(changeProfilePromise, {
    //   loading: "Updating Profile",
    //   success: "Profile Changed Successfully",
    //   error: "Profile Update Failed",
    // });
  };

  const uploadDocument = (e,docname) => {
   
   const toastId = toast.loading(`Please wait ${docname} file upload in progress`);
   UserService.SaveCompanyDocuments(e,docname, user.userData.userId)
     .then((res) => {
        toast.dismiss(toastId);
       if (res && res.isSuccessful) {
         toast.success(`${docname} file upload successful`);
       } else {
         toast.error(
           "Upload Failed, Delete file and Try again"
         );
       }
     })
     .catch((err) => {
       // console.error(err);
     });
 };
  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between my-6">
        <div>
          <h4 className="text-xl font-semibold text-left text-lime-700 md:text-2xl">
            Business Details
          </h4>
          <h6 className="">
          Please be sure to update your business information if it has changed.
          </h6>
        </div>
        

        <div>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>

    
      <form onSubmit={handleSubmit} className={`w-full flex flex-col`}>
        <FormGrid>
         
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Company Name
            </span>
            <Input
              id="companyName"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Company Name"
              type="text"
              value={state.companyName||''}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Phone Number</span>
            <Input
              id="phoneNumber"
              onChange={handleChange}
              disabled={disabled}
              placeholder="eg: 0505707987"
              type="text"
              value={state.phoneNumber||''}
              required
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Email Address
            </span>
            <Input
              id="email"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Email Address"
              type="email"
              value={state.email||''}
              required
            />
          </label>
        </FormGrid>
        <hr className="my-3" />
        <h2 className="w-full my-4 text-xl font-medium text-left text-lime-700">
          Business Certificate Info
        </h2>
        <FormGrid>
          
       
          <div className="block">
          <h2 className="block mb-3 text-gray-700 text-md">Company Logo</h2>
          {disabled ? (
              <div
                className="flex items-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-md shadow-sm pointer "
                style={{ backgroundImage: `url(${state.logoUrl})` }}
              >
                {state.logoUrl ? (
                  <a href={state.logoUrl}>
                    <BsDownload/>
                  </a>
                  ):(
                    <span className="text-gray-400"> No File</span>
                  )}
              </div>
            ) : (
              <DocumentUpload docname="logo" state={state} disabled={disabled} />
              // <ProfileImageSection docname={id} disabled={disabled} />
            )}
        </div>
        <div className="block">
          <h2 className="block mb-3 text-gray-700 text-md">Certificate of Registration</h2>
          {disabled ? (
            <div
              className="flex items-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-md shadow-sm pointer "
              style={{ backgroundImage: `url(${state.certificateOfRegistrationUrl})` }}
            >

                {state.certificateOfRegistrationUrl ? (
                  <a href={state.certificateOfRegistrationUrl}>
                    <BsDownload/>
                  </a>
                  ):(
                    <span className="text-gray-400"> No File</span>
                  )}
            </div>
            
          ) : (
            <DocumentUpload docname="certificate_of_registration" state={state} disabled={disabled} />
            // <SignatureSection docname={id} disabled={disabled} />
          )}
        </div>
        <div className="block">
          <h2 className="block mb-3 text-gray-700 text-md">Certificate of Commencement</h2>
          {disabled ? (
            <div
              className="flex items-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-md shadow-sm pointer "
              style={{ backgroundImage: `url(${state.certificateOfCommencementUrl})` }}
            >
                {state.certificateOfCommencementUrl ? (
                  <a href={state.certificateOfCommencementUrl}>
                    <BsDownload/>
                  </a>
                  ):(
                    <span className="text-gray-400"> No File</span>
                  )}
              
            </div>
          ) : (
            <DocumentUpload docname="certificate_of_commencement" state={state} disabled={disabled} />
          )}
        </div>
        </FormGrid>
        {!disabled && (
          <button
            type="submit"
            className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Update Profile
          </button>
        )}
      </form>
    </div>
  );
};

export default CompanyDetails;
