import React, { useState } from "react";

const Select = (props) => {
  //  console.log(props)
  const [value, setValue] = useState([]);
  // const [roleDisabled, setRoleDisabled] = useState(disabled);
  // const handleChange = (value) => setValue(value);
    return (
      
      // <Select
      //   className="w-full max-w-[30rem]"
      //   value={''}
      //   options={props.options}
      //   // onChange={(value) => handleChange(value)}
        
      // />
       <select
        className="w-full rounded rounded-sm border border-gray-300 py-1 px-2 outline-none disabled:bg-transparent disabled:border-none disabled:px-0 disabled:py-1 disabled:text-gray-400  "
        {...props}
        

      >
        <option  disabled>
          Make A Selection
        </option>
        {props.options &&
          props.options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
      </select> 
      
      
    );
  };
  

export default Select