import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import { useNavigate } from "react-router-dom";
import Search from "../sub-components/Search";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
const ProductTypes = () => {
  const navigate = useNavigate();
  const productColumns = [
    {
      name: "Name",
      selector: (row) => row.productTypeName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Active",
      selector: (row) =>
        row.isActive ? (
          <ActivePill>Active</ActivePill>
        ) : (
          <InactivePill>Inactive</InactivePill>
        ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div>
          <TableActions
            viewAction={() => navigate(`${row.productTypeId}`)}
            deleteAction={() =>
              window.confirm(
                `Are you sure you want to delete this Product Type`
              )
            }
          />
        </div>
      ),
    },
  ];

  const [pending, setPending] = useState(true);
  const [productTypes,setProductTypes] = useState([])
 
  useEffect(() => {
    handleProductTypes();
  }, []);

   

    const handleProductTypes = () => {
      StockService.GetProductTypes().then((res) => {
        
        setProductTypes(...productTypes, res);
        setPending(false);
        // console.log(concessions)
        
      });
    };

const [search,setSearch] = useState("");
  return (
    <div className="w-full">
      <AddnExport />
      <Search />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={productColumns}
        data={productTypes.filter((item) => {
          if (search === "") {
            return item;
          } else (
            item.description.toLowerCase().includes(search.toLowerCase())
          ) 
        })}
      progressPending={pending}
        pagination
      />
    </div>
  );
};

export default ProductTypes;

const data = [
  {
    id: 1,
    productName: "A Product Type",
    description: "Product Description",
    isActive: true,
  },
  {
    id: 2,
    productName: "A Product Type",
    description: "Product Description",
    isActive: true,
  },
  {
    id: 3,
    productName: "A Product Type",
    description: "Product Description",
    isActive: false,
  },
];
