import React, { useMemo } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useRoutes,
  useParams,
} from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
import { userEditRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";
import GoBack from "../sub-components/GoBack";
import { FaFileExport } from "react-icons/fa";
import { BiExport } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
const EditUser = () => {
   const params = useParams();
  //  console.log(params)
  const tabPaths = userEditRoutes.map(
    ({ path }) => `/dashboard/users/${params.id}/edit/${path}`
  );
  const { pathname } = useLocation();
  //  console.log({ pathname }, { tabPaths });
  const headerComponent = useMemo(
    () =>
      tabPaths.includes(pathname) ? <DashboardUserProfileHeader /> : <GoBack />,
    [pathname]
  );
  return (
    <div className="w-full h-full p-6 flex flex-col items-start">
      {headerComponent}
      <Outlet />
    </div>
  );
};

export default EditUser;

const DashboardUserProfileHeader = () => {
  return (
    <>
      <div className="w-full  flex justify-between items-center">
        <h1 className="text-4xl font-extrabold my-3 text-slate-700">Edit User Profile </h1>
      </div>
      <nav className="w-full text-left py-4 border-b border-gray-300/25 mb-3 flex flex-wrap">
        {userEditRoutes.map((route, index) => (
          <DashboardTabLink key={index} {...route} />
        ))}
      </nav>
    </>
  );
};
