import React from "react";
import { Outlet } from "react-router-dom";
import { profileRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";
const Orders = () => {
  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 text-4xl font-extrabold text-lime-700">Orders</h1>
      {/* <nav className="flex flex-wrap w-full py-5 mb-2 text-left border-b border-gray-300">
        {profileRoutes.map((route) => (
          <DashboardTabLink {...route} />
        ))}
      </nav> */}
      <Outlet />
    </div>
  );
};

export default Orders;
