import moment from "moment";
import type { ApiResponse } from "../models/ApiResponse";
import type { ProcessResponse } from "../models/ProcessResponse";
import type { UserInfo } from "../models/UserInfo";
import { getAxios } from "../helpers/api";
// import { AuthService } from "./AuthService";


class _UserService {
    private localStorageKeys = {
        currentUser: "CURRENT_USER"
    }
    
    async RegisterUser(username: string, password: string, firstName: string,lastName: string,clientTypeId: number,phoneNumber: string,email: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/onboard/client/user", { username, password, firstName, lastName, clientTypeId,phoneNumber,email});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Register. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async AddUserAddress(userId: number, location: string,city:string,postalCode:string,countryId=1, address1?: string,address2?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/add/user/address", { userId, location,city,postalCode,countryId, address1, address2 });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Address. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }
    async UpdateUserAddress(userId: number, location: string, address1?: string,address2?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/update/user/address", { userId, location, address1, address2 });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Address. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

    async AddCompanyInfo(userId: number, clientId: number, companyName: string, registrationDate: string, commencementDate: string,  email: string,phoneNumber?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/add/company/client/info", { userId,clientId, companyName,registrationDate,commencementDate, email, phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to Add Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

     async UpdateCompanyInfo(userId:number,clientId: number, companyName: string, email: string,phoneNumber?: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/update/company/info", { userId,clientId, companyName, email, phoneNumber });

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to update Company Info. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

   async GetUserById(id: number): Promise<UserInfo|any> {
        try {
            const res = await getAxios().get("/User/get/user/by/id/" + id);
            var response = res.data as ApiResponse<UserInfo>;
            if (response.isSuccessful) {
                if (response.data.clientFullInfo.commencementDate) response.data.clientFullInfo.commencementDate = moment(response.data.clientFullInfo.commencementDate).format('yyyy-MM-DD');
                if (response.data.clientFullInfo.registrationDate) response.data.clientFullInfo.registrationDate = moment(response.data.clientFullInfo.registrationDate).format('yyyy-MM-DD');
                 this.SetCurrentUser(response.data);
                return response.data;

            }
            return null;
        } catch (error) {
            
            return null;
        }
    }


 
    async UpdateUserInfo(data: UserInfo) {
        try {
            const res = await getAxios().post("/User/update/user", data);
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }



    async SaveUserImage(image: any, userId: any, onUploadProgress:any) {
        let data = new FormData();
        data.append('imageCollection', image);
        data.append('userId', userId);
        try {
            const res = await getAxios().post("/User/upload/user/image", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

    async SaveSignatureImage(image: any, userId: any, onUploadProgress: any) {
        let data = new FormData();
        data.append('imageCollection', image);
        data.append('userId', userId);
        try {
            const res = await getAxios().post("/User/upload/user/signature", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {

            return null;
        }
    }

    async SaveCompanyDocuments(image: any,docName:string, userId: string, onUploadProgress: any) {
        let data = new FormData();
        data.append(`${docName}`, image);
        data.append('userId', userId);
        
        try {
            const res = await getAxios().post("/User/upload/company/client/documents", data, { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress });
            return res.data as ApiResponse<any>;
        } catch (error) {
            
            return null;
        }
    }

     SetCurrentUser(userData?: any) {
        window.localStorage.setItem(this.localStorageKeys.currentUser, JSON.stringify(userData));
    }

      async changePassword(userId: number, oldPassword: string, newPassword: string): Promise<ProcessResponse> {
        try {

            const res = await getAxios().post("/User/reset/password/on/dashboard", { userId, oldPassword, newPassword});

            const data = res.data as ApiResponse<any>;
            if (data.isSuccessful) return { Successful: true, message: data.message };

            return { Successful: false, message: data.message ?? "Unable to change password. Please try again" }
        } catch (error) {
            
            return { Successful: false, message: "An error occurred. Please try again later" };
        }
    }

      async GetCountries(): Promise<any> {
        try {
            const res = await getAxios().get("/User/get/countries");
            var response = res.data as ApiResponse<UserInfo[]>;
            if (response.isSuccessful) {
                // if (response.data.dateOfBirth) response.data.dateOfBirth = moment(response.data.dateOfBirth).format('yyyy-MM-DD');
                 
                return response.data;

            }
            return [];
        } catch (error) {
            
            return null;
        }
    }
    sortData(data:any[]) {
        let sortedData;
        sortedData = [...data].sort((a, b) => {
            return b.dateCreated.localeCompare(a.dateCreated);
        });
        return sortedData;
    }

}

export const UserService = new _UserService();
