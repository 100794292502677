import React, { useState, useContext, useEffect } from "react";
import { RiAddCircleFill } from "react-icons/ri";
import './ordertable.css';
import Table from './Table';
import { StockService } from "../../../../../services/StockService";
const OrderTable = (props) => {
  console.log(props)
  const [addressState,setAddressState] = useState(props)
  
   const [rowsData, setRowsData] = useState(props.rowsData);
   const [totalItems, setTotalItems] = useState(props.totalItems);
   const [totalAmount, setTotalAmount] = useState(props.totalAmount);
   const [productlist,setProductList] = useState([])
    const addTableRows = ()=>{
        const rowsInput={
          productType:''
      ,    product:'',
          species:'',
          thickness:0,
          width:0,
          length:0,
          treatment:'',
          quantity:0,
          price:0.00,
          productId: 0,
          speciesId: 0,
          amount: 0,
          tickness: 0,
          treatmentTypeId: 0,
          productTypeId: 0,
        } 
        setRowsData([...rowsData, rowsInput])
      
    }
   const deleteTableRows = (index)=>{
        console.log(index)
        
        const rows = [...rowsData];
        if(rows.length > 1){
          rows.splice(index, 1);
          setRowsData(rows);
          console.log(rows);
        }
        
        const sumall = rows.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
        setRowsData(rows);
        setTotalItems(rows.length);
        setTotalAmount(sumall);
        props.setRowsData(rows);
        props.setTotalItems(rows.length);
        props.setTotalAmount(sumall);
        
   }
 
   const handleChange = (index, evnt)=>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
     console.log(rowsInput)
    rowsInput[index][name] = value;
    if(name === 'quantity'){
      rowsInput[index]["price"] = parseFloat(rowsInput[index]["product"]?.price)
      rowsInput[index]["amount"] = parseFloat(rowsInput[index]["product"]?.price)  * parseFloat(value);
    }
    if(name === 'thickness'){
      rowsInput[index]["tickness"] = parseInt(value)
      
    }


    const sumall = rowsInput.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
    setRowsData(rowsInput);
    setTotalItems(rowsInput.length);
    setTotalAmount(sumall);
    props.setRowsData(rowsInput);
     props.setTotalItems(rowsInput.length);
     props.setTotalAmount(sumall);

  }

  
  const handleSelectChange =(index,evnt)=> {
    console.log(evnt)
    const { name, value } = evnt;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = evnt;
    if(name === 'species'){
      rowsInput[index]["speciesId"] = evnt.speciesId
    }
    if(name === 'product' ){
      rowsInput[index]["price"] = evnt.price;
      rowsInput[index]["amount"] = evnt.price;
      rowsInput[index]["productId"] = evnt.productId
      rowsInput[index]["quantity"] = 1;
      const sumall = rowsInput.map(item => item.price).reduce((prev, curr) => prev + curr, 0);
      setRowsData(rowsInput);
      setTotalItems(rowsInput.length);
      setTotalAmount(sumall);

      
      props.setTotalItems(rowsInput.length);
      props.setTotalAmount(sumall);
    }
     if(name === 'productType'){
      let x = evnt.products.map((product)=>{
            product.value = product.productId;
            product.name = "product";
            product.label = product.productName;
            return product;
         })
      setProducts(x);
      rowsInput[index]["productTypeId"] = evnt.productTypeId
    }

    if(name === 'treatment'){
     
      rowsInput[index]["treatmentTypeId"] = evnt.treatmentTypeId
    }
    props.setRowsData(rowsInput);
  };

  const [products,setProducts] = useState([])
  const [productTypes,setProductTypes] = useState([])
  const [species,setSpecies] = useState([])
  const [treatmentTypes,setTreatmentTypes] = useState([])
  useEffect(() => {
    handleBasic();
  }, []);

  const handleBasic = () => {
    StockService.GetOrderBasic().then((res) => {
      let test = res.productTypes.map((productType)=>{
        productType.value = productType.productTypeId;
        productType.name = "productType";
        productType.label = productType.productTypeName;
        return productType;
     })

      setProductTypes(test);

      let test1 = res.species.map((specie)=>{
        specie.value = specie.speciesId;
        specie.name = "species";
        specie.label = specie.speciesName;
        return specie;
     })
      setSpecies(test1);

      let test3 = res.treatmentTypes.map((treatmentType)=>{
        treatmentType.value = treatmentType.treatmentTypeId;
        treatmentType.name = "treatment";
        treatmentType.label = treatmentType.treatmentTypeName;
        return treatmentType;
     })
      setTreatmentTypes(test3);
      
    });
  };


  

  return (
    <>
      <div className="w-full py-2 text-lime-700">
        <span className="place-order-txt">Add Items to Order</span>
      
      </div>
   
    <div>
      <div className="order-table"  onClick={addTableRows}>
        <RiAddCircleFill
          
          style={{ fontWeight: 'light', color: '#3e7c17', display: 'block', textAlign: 'end' }}
        />
        <span>Add Item</span>
      </div>
      <Table 
       products={products}
       species={species}
       productTypes={productTypes}
       treatmentTypes={treatmentTypes}
       setProductList={setProductList} 
       addressState={props.addressState} 
       handleCommentChange={props.handleCommentChange} 
       rowsData={rowsData} 
       deleteTableRows={deleteTableRows}
        handleChange={handleChange} 
        handleSelectChange={handleSelectChange} 
        totalItems={totalItems} 
        totalAmount={totalAmount} />
    </div>
    </>
  );
};

export default OrderTable;
