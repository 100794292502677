import React, { useState } from "react";
import Input from "../Input";
import toast from "react-hot-toast";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { AuthService } from "../../services/AuthService";

const PasswordReset = () => {
  let [params] = useSearchParams();
  let email = params.get("useremail");
  let token = params.get("txt");
  const [state, setState] = useState({
    password: "",
    confirm: "",
  });
  let history = useNavigate();
  // handler for changing form values

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState({
      ...state,
      [id]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // dummy login promise
    
    if (state.password !== state.confirm) {
      toast.error("New Password and Repeat Password does not match");
    } else {
      const toastId = toast.loading("User verification in progress");
      const t = AuthService.ResetPassword(email, state.password)
        .then((res) => {
          toast.dismiss(toastId);
          console.log(res);
          if (res.Successful) {
            toast.success(res.message);
            history(`/login`);
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error(error.error);
        });
    }
  };
  return (
    <form
      className="w-full  md:w-[30rem] flex flex-col space-y-5"
      onSubmit={handleSubmit}
    >
      <h1 className="font-bold text-left text-gray-800 text-2xl md:text-3xl mb-6">
        Password Reset
      </h1>
      <label className="block">
        <span className="block text-md font-medium text-gray-700 mb-3">
          New Password
        </span>
        <Input
          id="password"
          value={state.password}
          onChange={handleChange}
          required
          placeholder="New Password"
          type="password"
        />
      </label>
      <label className="block">
        <span className="block text-md font-medium text-gray-700 mb-3">
          Confirm Password
        </span>
        <Input
          id="confirm"
          value={state.confirm}
          onChange={handleChange}
          required
          placeholder="Confirm Password"
          type="password"
        />
      </label>
      <button
        type="submit"
        className="w-full bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md"
      >
        Reset Password
      </button>
    </form>
  );
};

export default PasswordReset;
