import React, { useState, useEffect } from "react";
import { testUsers, userRoles } from "../../../utils";
import UserCard from "./sub-components/UserCard";
import { UserService } from "../../../services/UserService";
const InternalUsers = () => {
  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      
      setUsers(...users, res);
    });
  };

   useEffect(() => {
 
       loadUsers();
     

   }, []);
  return (
    <div className="grid w-full grid-rows-auto grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">
      {users.map((user) => (
        <UserCard key={user.userId} {...user} />
      ))}
    </div>
  );
};

export default InternalUsers;
