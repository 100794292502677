import React, { useState, useEffect,useContext } from "react";
import { Link, Outlet, useLocation, useRoutes } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";
// import { testUsers } from "../../../utils";
import UserCard from "./sub-components/UserCard";
import { StockService } from "../../../services/StockService";
import UserContext from '../../../context/UserContext';
import ReactPaginate from "react-paginate";
const AllAddresses = () => {
  const user = useContext(UserContext);
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    handleGetAddresses();
  }, []);

  const handleGetAddresses = () => {
    StockService.GetDeliveryAddresses(user.userData.userId).then((res) => {
    
     setAddressList(...addressList,res)

    });
  };

  const itemsPerPage  = 6;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(addressList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(addressList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage,addressList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % addressList.length;
    console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <h1 className="my-3 text-4xl font-extrabold text-lime-700">Delivery Addresses</h1>
        <div className="flex items-center">
          <Link
            to="add"
            className="rounded rounded-full p-2 shadow-lg bg-[#3e7c17] hover:bg-[#356C14] mr-2"
          >
            <RiAddLine className="text-lg text-white" />
          </Link>
         
        </div>
      </div>    
      <div className="grid w-full grid-rows-auto grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4">
     
      {addressList && addressList.length > 0 ? (

          currentItems.map((user) => (
            <UserCard key={user.deliveryAddressId} {...user} />
          ))




        ) : (
          <div className="flex ">
            <div className="px-1 text-gray-500 justify-self-center">
              No Current Addresses !
            </div>
            
          </div>
        )}
    </div>
      <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          pageClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          pageLinkClassName="page-item"
          previousClassName="page-item"
          previousLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 focus:shadow-none"
          nextClassName="page-item"
          nextLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"

          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName=" flex justify-center mx-auto mt-10"
          activeClassName="active bg-lime-600 text-white"
      />
    </>
    
  );
};

export default AllAddresses;
