
import { createContext  } from "react";

let user = {
    address1: "",
    address2: "",
    clientFullInfo: {
        certificateOfCommencementUrl: "",
        certificateOfRegistrationUrl: null,
        clientCode: "TCL-BUBSPLTWNEENMJO",
        clientId: 1,
        clientTypeId: 1,
        commencementDate: "0001-01-01T00:00:00",
        companyClientInfoId: 0,
        companyName: '',
        email: '',
        isActive: false,
        logoFile: null,
        logoUrl: null,
        phoneNumber: '',
        registrationDate: "0001-01-01T00:00:00",
        userId: 1,
        userTypeId: 2,
        clientType: {
            clientTypeId: 0,
            clientTypeName: "",
            dateCreated: "",
            description: "",
            isActive: true
            }
        },
    createdBy: "",
    dateCreated: "",
    email: "",
    firstName: "",
    imageFile: null,
    imageUrl: '',
    isActive: true,
    lastName: "",
    location: "",
    phoneNumber: "",
    registrationStatusId: 0,
    signatureFile: null,
    signatureUrl: null,
    uniqueCode: "",
    userAddressId: 0,
    userId: 0,
    userImageId: 0,
    userTypeId: 0,
    city:"",
    additional_info:""
}; 
let authStatus = false;
let userDataString = window.localStorage.getItem("CURRENT_USER");

if (userDataString){
    user = JSON.parse(userDataString);
    authStatus = true;
}

const UserContext = createContext({
     authenticated: authStatus,  
     userData: user
    });
export default UserContext;



