import React, { useState, useContext, useEffect } from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import TextArea from "../../../TextArea";
import GoBack from "../../sub-components/GoBack";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import DocumentUpload from "../../sub-components/DocumentUploads";
import { StockService } from "../../../../services/StockService";
import { BsDownload } from "react-icons/bs";
import {
  useParams,
} from "react-router-dom";
const EditConcession = () => {
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    concessionId:0,
    concessionName: "",
    concessionSize: "",
    description: "",
    totalNumberOfTrees: "",
    address: "",
    isActive:true,
    permitNumber: "",
    permitExpiryDate: "",
    permitDocument: "",
    permitDocumentUrl:"",
    geoLocation:"",
    registrationDate:""

  });
   const params = useParams();
  const toggleDisable = () => setDisabled((oldState) => !oldState);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   const toastId = toast.loading("Concession Update In Progress...");
    StockService.UpdateConcession(
      state.concessionId,
      state.concessionName,
      state.concessionSize,
      state.description,
      state.registrationDate,
      state.totalNumberOfTrees,
      state.address,
      state.geoLocation,
      state.permitNumber,
      state.permitExpiryDate,
      state.permitDocument
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };


 
  useEffect(() => {
    handleConcession();
  }, []);

  const handleConcession = () => {
    StockService.GetConcession(params.id).then((res) => {
      // console.log(res)
      setState({
      ...state,
      concessionId:  res.concessionId ||"",
      concessionName:  res.concessionName ||"",
      concessionSize:  res.size ||"",
      description:  res.description ||"",
      totalNumberOfTrees:  res.totalNumberOfTrees ||"",
      address:  res.address ||"",
      isActive: res.isActive ||false,
      permitNumber:  res.permitNumber ||"",
      permitExpiryDate:  res.permitExpiryDate ||"",
      permitDocument:  res.permitDocument ||"",
      permitDocumentUrl:res.permitDocumentUrl||"",
      registrationDate:res.registrationDate||"",
      geoLocation:res.geoLocation||""
      
    });
      // console.log(concession)
    });
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <GoBack />
      <div className="flex space-x-2 self-end">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold hover:shadow-md rounded rounded-md"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
            >
              Cancel
            </button>
          </div>
        )}
        {state.isActive ? (
          <button type='button' className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50">
            - Deactivate
          </button>
        ) : (
          <button type='button' className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50">
            + Activate
          </button>
        )}

      </div>
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        {disabled ? "Concession Details" : "Edit Concession"}
      </h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Concession Name
          </span>
          <Input
            placeholder="Concession Name"
            type="text"
            disabled={disabled}
            value={state.concessionName}
            onChange={handleChange}
            required
            id="concessionName"
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Concession Size
          </span>
          <Input
            placeholder="Concession Size"
            type="text"
            disabled={disabled}
            value={state.concessionSize}
            onChange={handleChange}
            required
            id="concessionSize"
          />
        </label>

        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Total Number of Trees
          </span>
          <Input
            id="totalNumberOfTrees"
            placeholder="Total Number of Trees"
            type="text"
            disabled={disabled}
            value={state.totalNumberOfTrees}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
          id="description"
            placeholder="Description"
            type="text"
            disabled={disabled}
            value={state.description}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Address</span>
          <TextArea
          id="address"
            placeholder="eg: 0505707987"
            type="text"
            disabled={disabled}
            value={state.address}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <h2 className=" text-xl font-medium text-gray-700 my-4">Permit Info</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Permit Number
          </span>
          <Input
          id="permitNumber"
            placeholder="Permit Number"
            type="text"
            disabled={disabled}
            value={state.permitNumber}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Permit Expiry Date
          </span>
          <Input
          id="permitExpiryDate"
            placeholder="Permit Expiry Date"
            type="date"
            disabled={disabled}
            value={state.permitExpiryDate}
          />
        </label>
      </FormGrid>

      <span className="block text-md  text-gray-700 mb-3 mt-4">
        Permit Document
      </span>
      {disabled ? (
         <a href={state.permitDocumentUrl}>
                    
                    <BsDownload>
                     
                    </BsDownload>
                  
                  </a>
      
      ):(
        <DocumentUpload state={state} disabled />
      )}
      {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Edit Concession
        </button>
      )}
    </form>
  );
};

export default EditConcession;
