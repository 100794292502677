import React, { useState, useEffect,useContext } from "react";
import manCTimber from "../../../../assets/man_cutting_timber.jpg";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../context/UserContext";
import { RiBuilding4Fill, RiBuilding4Line } from "react-icons/ri";
const UserCard = (props) => {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const {
    deliveryAddressId=0,
    name = "Issah",
    location = "Muniru",
    city = "Admin",
    postalCode = "",
    gpsAddress="",
    country = "",
    
  } = props;

  const viewUser = () => {
    navigate(`/dashboard/delivery-address/${deliveryAddressId}`);
  };
  return (

    
      <div onClick={viewUser} className="block bg-white ">
        <div className="inline-flex items-center justify-center px-6 py-3 text-gray-600 border-b border-gray-300">
        {/*  */}
          
          <RiBuilding4Line className="inline-block ml-2 mr-1"/>
          
          <div className="inline-block ">Delivery Address </div>
        </div>
        <div className="justify-center p-2">
          {/* <h5 className="mb-2 text-xl font-medium text-gray-400">Special title treatment</h5> */}
         
            <ul className="text-gray-600 bg-white justify-left">
              <li className="w-full px-6 py-2 "><span className="pr-1 text-gray-500">Name:</span>{user.userData.firstName}  {user.userData.lastName}</li>
              <li className="w-full px-6 py-2 "><span className="pr-1 text-gray-500">Location:</span>{location}</li>
              <li className="w-full px-6 py-2 "><span className="pr-1 text-gray-500">City:</span>{city}</li>
              <li className="w-full px-6 py-2 "><span className="pr-1 text-gray-500">Postal Code:</span>{postalCode}</li>
              <li className="w-full px-6 py-2 "><span className="pr-1 text-gray-500">GPS Address:</span>{gpsAddress}</li>
              {/* <li className="w-full px-6 py-2 rounded-b-lg"><span>Country:</span>{country}</li> */}
            </ul>
          
          
        </div>
        <div className="px-6 py-3 text-gray-600 border-t border-gray-300">
        <button type="button" className="justify-center w-full   px-6 py-2.5 bg-lime-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-lime-600 hover:shadow-lg focus:bg-lime-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-lime-800 active:shadow-lg transition duration-150 ease-in-out">Edit</button>
        </div>
      </div>
 
    
  );
};

export default UserCard;
