import React, { useState, useEffect } from "react";
import UserCard from "./sub-components/UserCard";
// import { testUsers } from "../../../utils";
import { UserService } from "../../../services/UserService";
const ActiveUsers = () => {
  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllUsers().then((res) => {
      // console.log(res);
      setUsers(...users, res);
      // console.log(users);
    });
  };

  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <div className="grid w-full grid-rows-auto grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">
      {users
        .filter((user) => user.isActive != false)
        .map((user) => (
          <UserCard key={user.userId} {...user} />
        ))}
    </div>
  );
};

export default ActiveUsers;
