import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import Search from "../sub-components/Search";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
const data = 
    {
      id: 1,
      concessionName: "",
      description: "",
      size: 0,
      isActive: true,
    }
  
const Concessions = () => {
  const navigate=useNavigate();
  
  const [pending, setPending] = useState(true);
  
  const concessionColumns = [
    {
      name: "Name",
      selector: (row) => row.concessionName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.isActive?<ActivePill >Active</ActivePill>:<InactivePill>Inactive</InactivePill>,
      sortable: true,
    },
    {
      name:'Actions',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`${row.concessionId}`)} deleteAction={()=>window.confirm(`Are you sure you want to delete this concession`)} />
      </div>
    }
  
  
  ];
  
  const [concessions,setConcessions] = useState([])
 
  useEffect(() => {
  handleConcessions();
}, []);

   

    const handleConcessions = () => {
      StockService.GetAllConcessions().then((res) => {
        
        setConcessions(...concessions, res);
        setPending(false);
        // console.log(concessions)
        
      });
    };

const [search,setSearch] = useState("");
const handleSearch = (event) => {
    setSearch(event.target.value); 
  };
  return (
    <div className="w-full">
      <AddnExport />
      <Search   updateList={handleSearch} />
      <DataTable
        className="w-full rounded rounded-lg"
        columns={concessionColumns}
        // data={concessions}
        data={concessions.filter((item) => {
            if (search === "") {
              return item;
            } else(
              item.description.toLowerCase().includes(search.toLowerCase())
            ) 
          })}
        progressPending={pending}
        pagination
        
      />
    </div>
  );
};

export default Concessions;



// const data = [
//     {
//       id: 1,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: true,
//     },
//     {
//       id: 2,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: true,
//     },
//     {
//       id: 3,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: false,
//     },
//     {
//       id: 4,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: true,
//     },
//     {
//       id: 5,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: false,
//     },
//     {
//       id: 6,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: true,
//     },
//     {
//       id: 7,
//       concessionName: "A Concession",
//       description: "Concession Description",
//       size: 123.0,
//       isActive: false,
//     },
//   ];
  