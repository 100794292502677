import React, { useContext, useEffect, useState } from "react";
import Input from "./Input";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { UserService } from "../services/UserService";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import UserContext from "../context/UserContext";
const Login = () => {
  const user = useContext(UserContext);
  const [userState, setUserState] = useState(user);

  let history = useNavigate();
  useEffect(() => {
    if (userState.authenticated) {
      history("/dashboard");
    }
  });

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  // handler for changing form values
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState({
      ...state,
      [id]: value,
    });
  };

  // handler for login
  const handleSubmit = (e) => {
    e.preventDefault();
    // dummy login promise
    const toastId = toast.loading("Login verification in progress");
    const t = AuthService.Login(state.email, state.password)
      .then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          UserService.GetUserById(res.data.userId).then((ress) => {
            // console.log(user);
             toast.success(res.message);
             window.location.reload();
          });
         
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.error);
      });

  };

  // state sideeffect
  useEffect(() => {
    console.log({ state });
  }, [state]);
  return (
    <form
      className="w-full  md:w-[30rem] flex flex-col space-y-5"
      onSubmit={handleSubmit}
    >
      <h1 className="mb-0 text-2xl font-bold text-left text-lime-700 md:text-3xl">
        Sign In
      </h1>
      <p className="mb-6 text-xl text-left text-gray-400 md:text-xl">Login to place an order</p>
      <label className="block">
        <span className="block mb-3 font-medium text-gray-700 text-md">
          Username
        </span>
        <Input
          placeholder="Username"
          id="email"
          type="text"
          value={state.email}
          onChange={handleChange}
          required
        />
      </label>
      <label className="flex flex-col block">
        <span className="block mb-3 font-medium text-gray-700 text-md">
          Password
        </span>
        <Input
          placeholder="Password"
          id="password"
          type="password"
          value={state.password}
          onChange={handleChange}
          required
        />
        {/* <Link
          to={`/password-reset-request`}
          className="self-end mt-2 text-sm text-blue-600 hover:underline "
        >
          Forgot Password?
        </Link> */}
      </label>
      <button
        type="submit"
        className="w-full bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md"
      >
        SIGN IN
      </button>
      <p>
              Don't have an account?
              <Link
                to="/signup"
                style={{
                  textDecoration: 'none',
                  color: 'green',
                  marginLeft: '5px',
                }}
              >
                Sign Up
              </Link>
            </p>
            <p>
              Forgot Password?
              <Link
                to="/password-reset-request"
                style={{
                  textDecoration: 'none',
                  color: 'green',
                  marginLeft: '5px',
                }}
              >
                Reset Password
              </Link>
            </p>
    </form>
  );
};

export default Login;
