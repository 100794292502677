import React, { useState,useEffect } from "react";
import { RiEditLine } from "react-icons/ri";
import Select from "react-select";
import toast from "react-hot-toast";
import { UserService } from "../../../../services/UserService";
const RoleForm = (props) => {
  
  const { userData,userRoles,setUserRoles,showEdit } = props;
  
  const [value, setValue] = useState([]);
  const [roleDisabled, setRoleDisabled] = useState(true);
  const [roleEdit, setRoleEdit] = useState(showEdit);
  const toggleRoleDisability = () => setRoleDisabled((oldState) => !oldState);
  const handleChange = (value) => setValue(value);
  const pluck = property => element => element[property]

  const handleSubmit = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Editing User Roles");
    
    const roleIds = value.map(pluck('roleId')).join(";");
    // console.log(roles)
    UserService.GrantUserRole(userData.id,roleIds)
      .then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          toast.success(res.message);
          setUserRoles(value);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        // toast.error(error.error);    
      });
    
    setRoleDisabled(true)
  };
 

  const [roles, setRoles] = useState([]);

  const loadRoles = () => {
    UserService.GetRoles().then((res) => {
      let test = res.map((role)=>{
        role.value = role.roleId;
        role.label = role.roleName;
        role.isFixed = true;
        return role;
     })
      setRoles(...roles, test);
    });
  };

   useEffect(() => {
       loadRoles();
   }, []);

   useEffect(() => {
    handleGetUser();
  }, [userRoles]);

  const handleGetUser = () => {
    let test = userRoles.map((role)=>{
      role.value = role.roleId;
      role.label = role.roleName;
      role.isFixed = true;
      return role;
   })
   setValue(test);
   
  };


  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <div className="flex justify-between w-full items-center">
        <h2 className=" text-xl font-medium text-gray-700 my-4">Roles</h2>
        <div>
          {roleDisabled && roleEdit&& (
            <button
              type="button"
              onClick={toggleRoleDisability}
              className="text-xs text-slate-600 bg-slate-200 px-2 py-0.5 rounded rounded-full mr-2 hover:font-medium hover:ring-2 hover:ring-slate-600/50 flex items-center"
            >
              <RiEditLine className="mr-1" /> Edit Roles
            </button>
          )}
          {!roleDisabled && roleEdit&&(
            <button
              type="button"
              onClick={toggleRoleDisability}
              className="text-xs text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-full mr-2 hover:font-medium hover:ring-2 hover:ring-red-600/50 flex items-center"
            >
              <RiEditLine className="mr-1" /> Cancel{" "}
            </button>
          )}
        </div>
      </div>

      {/* <RolePill/> */}
      <Select
        className="w-full max-w-[30rem]"
        value={value}
        isDisabled={roleDisabled}
        options={roles}
        onChange={(value) => handleChange(value)}
        isMulti
        // {...rest}
      />

      {!roleDisabled && (
        <button className="self-end rounded rounded-lg hover:shadow-md px-3 py-1 text-white bg-tclPrimary">Save</button>
      )}
    </form>
  );
};

export default RoleForm;

// const options = [
//   { value: "admin", label: "Admin", isFixed: true },
//   { value: "non-admin", label: "Non-Admin", isFixed: true },
// ];
