import React, { useState, useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import toast from "react-hot-toast";
import TextArea from "../../../TextArea";
import TeamMemberForm from "./sub-components/TeamMemberForm";
// import Select from "../../../Select";
import { UserService } from "../../../../services/UserService";
import { StockService } from "../../../../services/StockService";
import Select, { OptionProps } from 'react-select';
import { Link, useNavigate } from "react-router-dom";
const AddTeam = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    teamName: "",
    isActive: false,
    description: "",
    managerId: "",
    supervisorId: "",
    driverId: "",
    teamMembers: "",
  });

  const [teamMembers,setTeamMembers] = useState("")
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const [selectedManagerOption, setSelectedManagerOption] = useState(null);
  const [selectedSupervisorOption, setSelectedSupervisorOption] = useState(null);
  const [selectedDriverOption, setSelectedDriverOption] = useState(null);

  
  const handleSupervisorChange =(e)=> {
    // console.log(e)
    setSelectedSupervisorOption(e);
    state.supervisorId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const handleDriverChange =(e)=> {
    // console.log(e)
    setSelectedDriverOption(e);
    state.driverId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };
  const handleMangerChange =(e)=> {
    // console.log(e)
    setSelectedManagerOption(e);
    state.managerId = e.value
    const filteredUsers = users.filter((user) => user.userId !== e.userId );
    setUsers(filteredUsers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    state.teamMembers = teamMembers;
    console.log(state);
    const toastId = toast.loading("Creating new Team ... ");
     StockService.CreateTeam(
       state.teamName,
       state.description,
       state.teamMembers,
       state.supervisorId,
       state.managerId,
       state.driverId
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         history("/dashboard/stock-management/teams");
         
       } else {
         toast.error(res.message);
       }
     });
  };

  const [users, setUsers] = useState([]);
  const loadUsers = () => {
    UserService.GetAllInternalUsers().then((res) => {
      let data = res.map((user)=>{
        user.value = user.userId;
        user.label = user.firstName+' '+user.lastName;
        return user;
     })
      setUsers(...users, data);
    });
  };

   useEffect(() => {
 
       loadUsers();
     

   }, []);
  return (
    <>
       <GoBack />
       <form className="w-full flex flex-col" onSubmit={handleSubmit}>
   
      <h2 className=" text-xl font-medium text-gray-700 my-2">New Team</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Team Name
          </span>
          <Input
            placeholder="Team Name"
            type="text"
            value={state.teamName}
            onChange={handleChange}
            required
            id="teamName"
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
            id="description"
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Supervisor
          </span>
          
          
          <Select
            id="supervisorId"
            type="text"
            options={users}
            value={selectedSupervisorOption}
           
            onChange={handleSupervisorChange}
            required
            
          />
          {/* <Input
            placeholder="Supervisor"
            type="text"
            value={state.supervisorId}
          /> */}
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Manager
          </span>
          <Select
            id="managerId"
            type="text"
            options={users}
            value={selectedManagerOption }
            onChange={handleMangerChange}
            required
          />
          {/* <Input
            placeholder="Manager"
            type="text"
            value={state.managerId}
          /> */}
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Driver
          </span>
          <Select
            id="driverId"
            type="text"
            options={users}
            value={selectedDriverOption }
            onChange={handleDriverChange}
            required
          />
          {/* <Input
            placeholder="Driver"
            type="text"
            value={state.driverId}
          /> */}
        </label>
      </FormGrid>
      <h3 className=" text-lg font-medium text-gray-700 my-2">Other Team Members</h3>
      <TeamMemberForm disabled={false} options={users}  setTeamMembers={setTeamMembers}/>

      {/* <div className='mt-3'>
        <input
          placeholder="Number of Trees Remaining"
          type="checkbox"
          id="isActive"
          value={state.isActive}
        />
        <span className="text-md  text-gray-700 ml-3">Active</span>
      </div> */}
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Team
      </button>
    </form>
    </>
    
  );
};

export default AddTeam;
