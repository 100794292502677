import React, { useState, useContext, useEffect,useCallback } from "react";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import {
  ProfileImageSection,
  SignatureSection,
} from "../sub-components/DocumentUploads";
import UserContext from "../../../context/UserContext";
import { UserService } from "../../../services/UserService";
import { BsDownload,BsPencilFill } from "react-icons/bs";
import ImageViewer from 'react-simple-image-viewer';
import {AiOutlineEye} from "react-icons/ai"
const PersonalDetails = () => {
  const user = useContext(UserContext);
  const [state, setState] = useState(user.userData);
  const loadUserProfile = () => {
    UserService.GetUserById(parseInt(user.userData.userId)).then((res) => {
      setState(state,res)
    });
  };

   useEffect(() => {
    
         loadUserProfile();
       
   }, []);

  
  const id = state.userId;
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Updating Profile");
    const updateProfilePromise = UserService.UpdateUserInfo({
      userId: state.userId,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phoneNumber: state.phoneNumber
    }).then((res) => {
      toast.dismiss(toastId);
      if (res.isSuccessful) {
        toast.success(res.message);
        UserService.GetUserById(state.userId).then(
          (ress) => {
            window.location.reload();
          }
        );
      } else {
        toast.error(res.message);
      }
    });
    // handles the toast loader for the login process
    // toast.promise(changeProfilePromise, {
    //   loading: "Updating Profile",
    //   success: "Profile Changed Successfully",
    //   error: "Profile Update Failed",
    // });
  };

  const [currentImage, setCurrentImage] = useState('');
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    'http://placeimg.com/1200/800/nature',
    'http://placeimg.com/800/1200/nature',
    'http://placeimg.com/1920/1080/nature',
    'http://placeimg.com/1500/500/nature',
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage('');
    setIsViewerOpen(false);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between my-6">
        <div>
          <h4 className="text-xl font-semibold text-left text-lime-700 md:text-2xl">
            User Details
          </h4>
          <h6 className="">
          Please be sure to update your personal information if it has changed.
          </h6>
        </div>
        

        <div>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-gray-700 rounded rounded-md shadow-lg md:block hover:bg-gray-800 text-medium"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>

    
      <form onSubmit={handleSubmit} className={`w-full flex flex-col`}>
        <FormGrid>
          {/* <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Username</span>
            <Input
              id="username"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Username"
              type="text"
              value={state.username}
            />
          </label> */}
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              First Name
            </span>
            <Input
              id="firstName"
              onChange={handleChange}
              disabled={disabled}
              placeholder="First Name"
              type="text"
              value={state.firstName}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">Last Name</span>
            <Input
              id="lastName"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Last Name"
              type="text"
              value={state.lastName}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Email Address
            </span>
            <Input
              id="email"
              onChange={handleChange}
              disabled={disabled}
              placeholder="Email Address"
              type="email"
              value={state.email}
            />
          </label>
          <label className="block">
            <span className="block mb-3 text-gray-700 text-md">
              Phone Number
            </span>
            <Input
              id="phoneNumber"
              onChange={handleChange}
              disabled={disabled}
              placeholder="eg: 0505707987"
              type="tel"
              value={state.phoneNumber}
            />
          </label>
          <div className="block">
          <h2 className="block mb-3 text-gray-700 text-md">Profile Photo</h2>
          {disabled ? (
              <div
                className="flex items-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-md shadow-sm pointer "
                style={{ backgroundImage: `url(${state.imageUrl})` }}
              >
                 {state.imageUrl ? (
                  <a href={state.imageUrl} onClick={  (e) =>{e.preventDefault(); openImageViewer(state.imageUrl);}  }>
                    <AiOutlineEye className="text-2xl text-green-600" />
                  </a>

                  ):(
                    <span className="text-gray-400"> No File</span>
                  )}
              </div>

            ) : (
              <ProfileImageSection id={id} disabled={disabled} />
            )}
        </div>
        <div className="block">
          <h2 className="block mb-3 text-gray-700 text-md">Signature</h2>
          {disabled ? (
            <div
              className="flex items-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-md shadow-sm pointer "
              style={{ backgroundImage: `url(${state.signatureUrl})` }}
            >
               {state.signatureFile ? (
                  <a href={state.signatureUrl} onClick={  (e) =>{e.preventDefault(); openImageViewer(state.signatureUrl);} }>
                    <AiOutlineEye className="text-2xl text-green-600" />
                  </a>
                  ):(
                    <span className="text-gray-400"> No File</span>
                  )}
            </div>
          ) : (
            <SignatureSection id={id} disabled={disabled} />
          )}
        </div>
        </FormGrid>
        {isViewerOpen && (
            <ImageViewer
                src={ [currentImage] }
                currentIndex={ 0 }
                disableScroll={ false }
                closeOnClickOutside={ true }
                onClose={ closeImageViewer }
            />
        )}
        {!disabled && (
          <button
            type="submit"
            className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
          >
            Update Profile
          </button>
        )}
      </form>
    </div>
  );
};

export default PersonalDetails;
