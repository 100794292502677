import React, { useState, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { FormGrid } from "./AddANewUser";
import SelectCountry from "../../SelectCountry";
const EditUserAddress = () => {
  const [state, setState] = useState({
    location: "",
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
    country:"",
  });

  const params = useParams();
  const [countries, setCountries] = useState([]);

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  useEffect(() => {
    handleGetUser();
  },[]);
   useEffect(() => {
     handleGetCountry();
   }, []);

  const handleGetUser = () => {
    UserService.GetAdminById(params.id).then((res) => {
      setState({
        ...state,
        location: res.location || "",
        address1: res.address1 || "",
        address2: res.address2 || "",
        postalCode: res.postalCode || "",
        city: res.city || "",
        country: res.country || res.countryId,
      });
      //  console.log(state)
    });
  };

  const handleGetCountry = () => {
    UserService.GetCountries().then((res) => {
     let data = Array.from(res);
     setCountries(data);
      //  console.log(state)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Editing User");
    UserService.AddUserAddress(
      params.id,
      state.location,
      state.city,
      state.postalCode,
      state.country,
      state.address1,
      state.address2
    ).then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.error);
      });
    // handles the toast loader for the login process
    // toast.promise(editUserPromise, {
    //   loading: "Editing User",
    //   success: "User Edited Successfully",
    //   error: "User Edit Failed",
    // });
    // editUserPromise.then((results) => console.log(results));
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <h2 className=" text-xl font-medium text-gray-700 my-2">Address Info</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Location</span>
          <Input
            placeholder="Location"
            type="text"
            id="location"
            onChange={handleChange}
            value={state.location}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Address 1</span>
          <Input
            placeholder="Address 1"
            type="text"
            id="address1"
            onChange={handleChange}
            value={state.address1}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Address 2</span>
          <Input
            placeholder="Address 2"
            type="text"
            id="address2"
            onChange={handleChange}
            value={state.address2}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Country</span>
          <SelectCountry
            id="country"
            type="text"
            options={countries}
            value={state.country}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">City</span>
          <Input
            placeholder="City"
            type="text"
            id="city"
            onChange={handleChange}
            value={state.city}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Postal Code</span>
          <Input
            placeholder="Postal Code"
            type="text"
            id="postalCode"
            onChange={handleChange}
            value={state.postalCode}
          />
        </label>
      </FormGrid>
      {/* <h2 className=" text-xl font-medium text-gray-700 my-4">Signature</h2>
      <SignatureSection /> */}
      <button
        type="submit"
        className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Edit User Address
      </button>
    </form>
  );
};

export default EditUserAddress;
