import React, { useState, useEffect,useContext,useRef } from "react";
import { useNavigate } from "react-router";
import { BiEdit } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { RiEditLine } from "react-icons/ri";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import UserContext from "../../../context/UserContext";
import Input from "../../Input";
import { UserService } from "../../../services/UserService";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
import { StockService } from "../../../services/StockService";
import './order.css';
import TableActions from "../StockManagement/sub-components/TableActions";
import swal from "sweetalert";
import { click } from "@testing-library/user-event/dist/click";
const MyOrderDetails = () => {
  const params = useParams();
  let history = useNavigate();
  const user = useContext(UserContext);
  
  const [addressState, setAddressState] = useState({
    userId: user.userData.userId,
    clientId: user.userData.clientFullInfo.clientId,
    clientName: user.userData.firstName+' '+user.userData.lastName,
    description: "",
    numberOfProducts: 0,
    totalAmount: 0,
    comment: "",
    email: "",
    phoneNumber: "",
    vatAmount: 0,
    deliveryAddress: {},
    location: "",
    gpsAddress: "",
    postalCode: "",
    city: "",
    country: "",
    destinationCountry:{},
    contractNumber:'',
    orderItems:{},
    orderId:0
  });

  const [rowsData, setRowsData] = useState([{
    amount: 0,
    comment: "",
    dateCreated: "",
    length: 0,
    orderId: 0,
    orderItemId: 0,
    price: 0,
    product: {},
    productId: 0,
    productType: {},
    productTypeId: 0,
    quantity: 0,
    species: {},
    speciesId: 0,
    tickness: 0,
    treatmentType: {},
    treatmentTypeId: 0,
    width: 0
  }]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  const [pending, setPending] = useState(false);
  const [products,setProducts] = useState([]);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      [id]: value,
    }));

    
  };


  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAddressOption, setSelectedAddressOption] = useState(null);
  const handleCountryChange =(e)=> {
    setSelectedOption(e);
    addressState.countryId = e.value
   
  };

  const handleAddressChange =(e)=> {
    setSelectedAddressOption(e);
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      ["deliveryAddressId"]: e
    }));
    let count = countries.find(c=>c.countryId === e.countryId);
    setSelectedOption(count);
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      ["countryId"]: count
    }));
  };
  const handleGetCountry = () => {
    UserService.GetCountries().then((res) => {
      let test = res.map((country)=>{
          country.value = country.countryId;
          country.label = country.countryName;
          return country;
      })
      setCountries(test);
    
    });
  };
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    handleGetCountry();
  }, []);
  const [delieveryAddress, setDeliveryAddress] = useState([]);
  useEffect(() => {
    handleGetAddress();
  }, []);
 
  const handleGetAddress = () => {
    StockService.GetDeliveryAddresses(parseInt(user.userData.userId)).then((res) => {
      let test = res.map((delieveryAdd)=>{
          delieveryAdd.value = delieveryAdd.deliveryAddressId;
          delieveryAdd.label = delieveryAdd.location;
          return delieveryAdd;
      })
      setDeliveryAddress(test);
    
    });
  };

  const loadOrderDetails = () => {
    StockService.GetOrderDetail(parseInt(params.id)).then((res) => {
      console.log(res.orderItems)

      setRowsData(res.orderItems)

      setAddressState(res)
    });
  };

   useEffect(() => {
    
         loadOrderDetails();
       
   }, []);

   useEffect(() => {
    
    console.log(rowsData)
 
  
}, [addressState,rowsData]);
const productColumns = [

  {
    name: "Product Type",
    selector: (row) => row.productType?.productTypeName,
  },
  {
    name: "Product",
    selector: (row) => row.product?.productName,
    grow: 2
  },
  {
    name: "Species",
    selector: (row) => row.species?.speciesName,
  },
  {
    name: "Treatment",
    selector: (row) => row.treatmentType?.treatmentTypeName,
  },
  {
    name: "Thickness",
    selector: (row) => row.tickness,
  },
  {
    name: "Width",
    selector: (row) => row.width,
  },
  {
    name: "Length",
    selector: (row) => row.length,
  },
 
  {
    name: "Quantity",
    selector: (row) => row.quantity,
  }, {
    name: "Price",
    selector: (row) => `GHS ${row.price}.00`,
  },
  {
    name:'Actions',
    selector:(row)=><div>
      <TableActions noView={true} deleteAction={(click)=>removeItem(row.orderItemId)} />
    </div>
  }

];

const removeItem = (itemId) => {
  swal({
    title: "Are you sure?",
    text: "Item will be removed permanently from the order",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // console.log(addressState.orderId)
      // console.log(itemId)
      const toastId = toast.loading('Please wait Item removal in progress');
      StockService.RemoveOrderItem(addressState.orderId,itemId)
        .then((result) => {
          toast.dismiss(toastId);
          if (result.Successful) {
            toast.success("Item Removal Successful");
            window.location.reload();
          
          }
        })
        .catch((err) => {});
    }
  });
};

const editAction = () => {
  history(`/dashboard/place-order/edit/${addressState.orderId}`);
};
  return (
    <>
      <div className="w-full py-2 text-xl text-lime-700">
        <span className="place-order-txt">Order Details</span>
      
      </div>


      <div className="w-full ">
     
          <div style={{display: 'flex',marginTop: '20px', marginBottom: '20px',}}>
          <table id="" className='w-full px-1 text-left text-gray-500 table-auto' >
            <thead >
              <tr>
                <th>Customer</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Contract Number</th>
                <th>Delivery Address</th>
                <th>Country</th>
                <th>Contract </th>
                <th>Date </th>
                
                <th>Action</th>
                
              </tr>
            </thead>
            <tbody>  
              <tr >
                <td>
                  {user.userData.firstName+' '+user.userData.lastName}
                </td>
                <td>
                  {addressState.email}
                </td>
                <td>
                {addressState.phoneNumber}
                </td>
                <td>
                {addressState.contractNumber}
                </td>
                <td>
                {addressState.deliveryAddress?.location}
                </td>
                <td>
                {addressState.destinationCountry.countryName}
                </td>
               
                
                <td>
                <a
                  href={addressState.contractFileUrl}
                  download
                >
                  <AiOutlineFilePdf className="text-xl text-gray-700" />
                </a>
                {/* <Link to={addressState.contractFileUrl} download className="p-2">
                  <AiOutlineFilePdf className="text-xl text-gray-700" />
                </Link> */}
                </td>
                <td>
                {addressState.dateCreated}
                </td>
                <td>
                <button
                  className={`text-gray-500 px-2 py-1  hover:bg-gray-700 hover:text-white`}
                  onClick={editAction}
                >
                  <FaEdit />
                </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>

          
      </div>
      <DataTable
        className="w-full h-full rounded table-auto"
        columns={productColumns}
        data={rowsData}
        progressPending={pending}
        pagination
      />
      <div className="flex w-full mt-2">
        <textarea rows={4} id="comment" className="w-full px-2 mb-2 text-gray-500" value={addressState.comment} placeholder="Additional Comments" onChange={(e)=>{e.preventDefault();}} />
        
      </div>
      {!addressState.destinationCountry.shortName === 'GH' && (
        <div className=" flex flex-col space-y-0.5 w-full md:w-[20rem] self-end mt-10">
          <div className={`px-2 py-3 ${
              "text-tclPrimary bg-tclPrimary/20"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{addressState.totalItems}</span>
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div className={`px-2 py-3 ${
              "text-tclPrimary bg-tclPrimary/20"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount*0.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div className={`px-2 py-3 ${
              "text-white bg-tclPrimary"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div className={`px-2 py-3 ${
              "text-white bg-tclPrimary"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount * 1.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
        {addressState.destinationCountry.shortName === 'GH' && (
        <div className="flex flex-col space-y-0.5 w-full md:w-[20rem] self-end mt-10">
          <div       className={`px-2 py-3 ${
            "text-tclPrimary bg-tclPrimary/20"
        } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{addressState.totalItems}</span>
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div  className={`px-2 py-3 ${
              "text-tclPrimary bg-tclPrimary/20"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount*0.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div  className={`px-2 py-3 ${
              "text-white bg-tclPrimary"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          {/*<div className="sum-underline"></div>*/}
          <div className={`px-2 py-3 ${
              "text-white bg-tclPrimary"
          } flex items-center justify-between first:rounded-t first:rounded-t-lg last:rounded-b last:rounded-b-lg`}>
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={addressState.totalAmount * 1.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
     
      
    </>
  );
};

export default MyOrderDetails;

const UserDetailsGrid = ({ children }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {children}
    </div>
  );
};
