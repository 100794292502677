import axios from "axios";
import { config } from '../environments/environment'
// const { publicRuntimeConfig } = getConfig();
const baseUrl = config.url.USER_API_URL;

export const getAxios = function (baseURL: string = baseUrl) {

    const instance = axios.create({
        baseURL,
        headers: { Authorization: 'Basic ' + config.url.API_KEY ,'Access-Control-Allow-Origin':'*'}
    });
    //  instance.interceptors.response.use((response) => {
    //     return response;
    //   }, function (error) {
    //     if (error.response.status === 400) return Promise.resolve(error.response);
    //     return Promise.reject(error.response);
    //   });

    return instance;
}

// export default axios.create({
//   baseURL: baseUrl,
//   headers: {
//      Authorization: 'Basic ' + config.url.API_KEY ,'Access-Control-Allow-Origin':'*'
//   }
// });

