const prod = {
  url: {
    USER_API_URL: "https://user-api.testmanagement.timbercl.com/api/v1",
    API_KEY: "REVWX1dFQl9VU0VSOkRFVl9GUk9OVEVORA==",
    PWD_RESET_URL:"https://client.testmanagement.timbercl.com/password-reset",
    STOCK_API_URL: "https://transaction-api.testmanagement.timbercl.com/api/v1",
    NOTIFICATION_API_URL: "https://notification-api.testmanagement.timbercl.com/api/v1",
    NOTIFICATION_API_URL_FOR_SIGNALR: "https://notification-api.testmanagement.timbercl.com/messenger",
  }
};

const dev = {
 url: {
  USER_API_URL: "https://user-api.testmanagement.timbercl.com/api/v1",
  API_KEY:"REVWX1dFQl9VU0VSOkRFVl9GUk9OVEVORA==",
  PWD_RESET_URL:"http://localhost:3000/password-reset",
  STOCK_API_URL: "https://transaction-api.testmanagement.timbercl.com/api/v1",
  NOTIFICATION_API_URL: "https://notification-api.testmanagement.timbercl.com/api/v1",
  NOTIFICATION_API_URL_FOR_SIGNALR: "https://notification-api.testmanagement.timbercl.com/messenger",
 }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
