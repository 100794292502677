import React, {useContext} from "react";
import { useLocation, Navigate } from "react-router-dom";
import UserContext from "../context/UserContext";
const PrivateRoute = ({ children }) => {

  const user = useContext(UserContext);
  //  console.log(user);
  // const user = {
  //   name: "admin",
  //   age: 12,
  // };
  let location = useLocation();
  if (!user.authenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export default PrivateRoute;
