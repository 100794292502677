import React, { useState, useEffect,useContext } from "react";
import { FiX } from "react-icons/fi";
import { HubConnection,IHttpConnectionOptions,IRetryPolicy,LogLevel, HubConnectionBuilder } from "@microsoft/signalr";
import { config } from '../../../../environments/environment'
import UserContext from '../../../../context/UserContext';
export const ViewMessage=(props)=>{
    const user = useContext(UserContext);
    const [showDetail, setShowDetail] = useState(false);
    const [connection, setConnection] = useState();
    var options = { withCredentials: false }
    var retyPolicy = {
        nextRetryDelayInMilliseconds: () => 5000
    }


    const markMessageRead = async () => {
    try {
        const connection = new HubConnectionBuilder()
            .withUrl(`${config.url.NOTIFICATION_API_URL_FOR_SIGNALR}`,options)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect(retyPolicy)
            .build();
        connection.on("NotificationRead", (messages) => {
            props.message.isRead = true;
            connection.invoke("GetAllMessagesForUser",user.userData.userId);
        });

        connection.on("NotificationForUser", (messages) => {
            props.setMessages(messages);
          });
        connection.onclose(e => {
            setConnection();
        });

        await connection.start();
        await connection.invoke("MarkNotificationMessageAsRead",props.message.messageId);
        setConnection(connection);
        } catch (e) {
        console.log(e);
        }
    }

    useEffect(() => {   
        if(showDetail && !props.message.isRead){
            markMessageRead();
        }
        
    }, [showDetail]);

    const truncate = (str)=>{
        // console.log(str)
        return str.length > 20 ? str.substring(0, 20) + " ... " : str;
    }
    
    return(
    // <span className="block px-2 py-1 rounded rounded-full text-tclPrimary bg-tclPrimary/25">{children}</span>
    <>
        <span className="block py-1" onClick={()=>!showDetail?setShowDetail(true):setShowDetail(false)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight">{truncate(props.message.messageText)} <span className="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-gray-200 text-gray-700 rounded">click to view more</span></span>
        {showDetail && (
            <div className="fixed top-0 bottom-0 right-0 flex flex-col visible max-w-full text-gray-700 transition duration-300 ease-in-out bg-white border-none shadow-sm outline-none offcanvas offcanvas-end bg-clip-padding w-96 " tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div className="flex items-center justify-between p-4 offcanvas-header">
            <h5 className="mb-0 font-semibold leading-normal offcanvas-title" id="offcanvasRightLabel">{props.message.messageTitle}</h5>
                <button type="button" onClick={()=>setShowDetail(false)}  className="box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 btn-close focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-bs-dismiss="offcanvas" aria-label="Close"><FiX/></button>
            </div>
            <div className="flex-grow p-4 overflow-y-auto offcanvas-body">
                {props.message.messageText}
                
            </div>
            </div>  
        )}
    </>
    
    )
}

