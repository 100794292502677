import React, { useState, useContext, useEffect } from "react";
import { RiEditBoxLine, RiCloseLine } from "react-icons/ri";
import Input from "../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../Users/AddANewUser";
import UserContext from "../../../context/UserContext";
import { UserService } from "../../../services/UserService";
import SelectCountry from "../../SelectCountry";
const PersonalAddress = () => {
  const user = useContext(UserContext);
//   const [state, setState] = useState(user.userData);
  const [state, setState] = useState({
    location: "",
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
    country: "",
  });

//   const params = useParams();
  const [countries, setCountries] = useState([]);
    useEffect(() => {
      handleGetUser();
    }, []);
    useEffect(() => {
      handleGetCountry();
    }, []);

    const handleGetUser = () => {
      UserService.GetAdminById(user.userData.userId).then((res) => {
        setState({
          ...state,
          location: res.location || "",
          address1: res.address1 || "",
          address2: res.address2 || "",
          postalCode: res.postalCode || "",
          city: res.city || "",
          country: res.country || res.countryId,
        });
        //  console.log(state)
      });
    };

    const handleGetCountry = () => {
      UserService.GetCountries().then((res) => {
        let data = Array.from(res);
        setCountries(data);
        //  console.log(state)
      });
    };


  const id = state.userId;
  const [disabled, setDisabled] = useState(true);
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

     const toastId = toast.loading("Editing User");
        UserService.AddUserAddress(
        user.userData.userId,
        state.location,
        state.city,
        state.postalCode,
        state.country,
        state.address1,
        state.address2
        ).then((res) => {
            toast.dismiss(toastId);
            console.log(res);
            if (res.Successful) {
            toast.success(res.message);
            } else {
            toast.error(res.message);
            }
        })
        .catch((error) => {
            toast.error(error.error);
        });


  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center my-6">
        <h4 className="text-left text-xl md:text-2xl text-gray-600 font-semibold">
          Personal Details
        </h4>

        <div>
          {disabled ? (
            <div className="">
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-2 shadow-lg bg-gray-700 hover:bg-gray-800 "
              >
                <RiEditBoxLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden md:block bg-gray-700 hover:bg-gray-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
              >
                Edit
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={toggleDisable}
                className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
              >
                <RiCloseLine className="text-lg text-white" />
              </button>
              <button
                onClick={toggleDisable}
                className="hidden md:block bg-red-700 hover:bg-red-800 py-2 px-4 text-white text-medium font-semibold shadow-lg rounded rounded-md"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>

      <form onSubmit={handleSubmit} className={`w-full flex flex-col`}>
        <h2 className=" text-xl font-medium text-gray-700 my-4">
          Address Info
        </h2>
        <FormGrid>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Location</span>
            <Input
              placeholder="Location"
              type="text"
              id="location"
              disabled={disabled}
              onChange={handleChange}
              value={state.location}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Address 1</span>
            <Input
              placeholder="Address 1"
              type="text"
              id="address1"
              disabled={disabled}
              onChange={handleChange}
              value={state.address1}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Address 2</span>
            <Input
              placeholder="Address 2"
              type="text"
              id="address2"
              disabled={disabled}
              onChange={handleChange}
              value={state.address2}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">Country</span>
            <SelectCountry
              id="country"
              type="text"
              options={countries}
              value={state.country}
              onChange={handleChange}
              disabled={disabled}
              required
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">City</span>
            <Input
              placeholder="City"
              type="text"
              id="city"
              disabled={disabled}
              onChange={handleChange}
              value={state.city}
            />
          </label>
          <label className="block">
            <span className="block text-md  text-gray-700 mb-3">
              Postal Code
            </span>
            <Input
              placeholder="Postal Code"
              type="text"
              id="postalCode"
              onChange={handleChange}
              value={state.postalCode}
              disabled={disabled}
            />
          </label>
        </FormGrid>

        {!disabled && (
          <button
            type="submit"
            className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2  my-3 rounded rounded-md self-end"
          >
            Update Address
          </button>
        )}
      </form>
    </div>
  );
};

export default PersonalAddress;
