import React, { useState, useContext, useEffect } from "react";
import AddnExport from "../sub-components/Add-Export";
import DataTable from "react-data-table-component";
import { ActivePill, InactivePill } from "../sub-components/StatusPill";
import { useNavigate } from "react-router-dom";
import Search from "../sub-components/Search";
import TableActions from "../sub-components/TableActions";
import { StockService } from "../../../../services/StockService";
const Species = () => {
  const navigate=useNavigate()
  const speciesColumns = [
    {
      name: "Name",
      selector: (row) => row.speciesName,
    },
    {
      name: "Description",
      selector: (row) => row.description,
    },
    {
      name: "Number Of Trees",
      selector: (row) => row.totalNumberOfTrees,
    },
    {
      name: "Active",
      selector: (row) => row.isActive?<ActivePill >Active</ActivePill>:<InactivePill>Inactive</InactivePill>,
    },
    {
      name:'Actions',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`${row.speciesId}`)} deleteAction={()=>window.confirm(`Are you sure you want to delete this species`)} />
      </div>
    }
  
  ];
  const [species,setSpecies] = useState([])
  useEffect(() => {
    handleSpecies();
  }, []);
  
     
  const [pending, setPending] = useState(true);
  const handleSpecies = () => {
    StockService.GetAllSpecies().then((res) => {
      
      setSpecies(...species, res);
      setPending(false);
      // console.log(concessions)
      
    });
  };
  const [search,setSearch] = useState("");
  const handleSearch = (event) => {
    setSearch(event.target.value); 
  };
  return (
    <div className="w-full">
      <AddnExport />
      <Search/>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={speciesColumns}
        // data={data}
        data={species.filter((item) => {
          if (search === "") {
            return item;
          } else(
            item.description.toLowerCase().includes(search.toLowerCase())
          ) 
        })}
        progressPending={pending}
        pagination
      />
    </div>
  );
};

export default Species;


const data = [
  {
    id: 1,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: true,
  },
  {
    id: 2,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: true,
  },
  {
    id: 3,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: false,
  },
  {
    id: 4,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: true,
  },
  {
    id: 5,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: false,
  },
  {
    id: 6,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123,
    isActive: true,
  },
  {
    id: 7,
    speciesName: "A Species",
    description: "Species Description",
    numberOfTrees: 123.0,
    isActive: false,
  },
];
