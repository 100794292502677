import React, { useContext, useEffect, useState, useRef } from 'react';
import { Outlet } from "react-router-dom";
import { profileRoutes,corporateRoutes } from "../../../utils";
import DashboardTabLink from "../sub-components/DashboardTabLink";
import UserContext from '../../../context/UserContext';
const Profile = () => {
  const user = useContext(UserContext);
  
  const [state, setState] = useState(user.userData);
  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 text-4xl font-extrabold text-lime-700">Profile</h1>
      {profileRoutes.length > 0 && state.clientFullInfo.clientTypeId == 2 ? (
      <nav className="flex flex-wrap w-full py-5 mb-2 text-left border-b border-gray-300">
        {corporateRoutes.map((route,index) => (
          <DashboardTabLink {...route} key={index} />
        ))}
      </nav>
      ):(
        <nav className="flex flex-wrap w-full py-5 mb-2 text-left border-b border-gray-300">
          {profileRoutes.map((route,index) => (
            <DashboardTabLink {...route} key={index} />
          ))}
        </nav>

      )
      
      }
      <Outlet />
    </div>
  );
};

export default Profile;
