import React, { useState, useContext } from "react";
import toast from 'react-hot-toast';
import { UserService } from "../../../services/UserService";
import UserContext from "../../../context/UserContext";
const ChangePassword = () => {
     const user = useContext(UserContext);
    const [passwordState, setPasswordState] = useState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });

    const handleChange = (e) => {
        const { value, id } = e.target;
        setPasswordState(oldPasswordState => ({ ...oldPasswordState, [id]: value }))
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const toastId = toast.loading("Updating Password");
        const changePasswordPromise = UserService.changePassword(
          user.userData.userId,
          passwordState.oldPassword,
          passwordState.newPassword
        ).then((res) => {
          toast.dismiss(toastId);
          if (res.Successful) {
            toast.success(res.message);
             
          } else {
            toast.error(res.message);
          }
        });
        // handles the toast loader for the login process
        // toast.promise(changePasswordPromise, {
        //     loading: "Updating Password",
        //     success: "Password Changed Successfully",
        //     error: "Password Update Failed",
        // });
    }

    return (
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-[50rem] flex flex-col"
      >
        <h4 className="mt-3 text-xl font-semibold text-left md:text-2xl text-lime-600 mb-7">
          Change Password
        </h4>
        <label className="block mb-3 md:flex md:items-end">
          <span className="block text-left md:flex  text-md font-medium text-gray-700 mb-3 min-w-[10rem]">
            Old Password
          </span>
          <input
            className="w-full px-2 py-1 border border-gray-300 rounded-sm rounded outline-none "
            placeholder="Password"
            id="oldPassword"
            type="password"
            value={passwordState.oldPassword}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-3 md:flex md:items-end">
          <span className="block text-left md:flex  text-md font-medium text-gray-700 mb-3 min-w-[10rem]">
            New Password
          </span>
          <input
            className="w-full px-2 py-1 border border-gray-300 rounded-sm rounded outline-none "
            placeholder="Password"
            id="newPassword"
            type="password"
            value={passwordState.newPassword}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block mb-3 md:flex md:items-baseline">
          <span className="block text-left md:flex text-md font-medium text-gray-700 mb-3 min-w-[10rem]">
            Confirm Password
          </span>
          <input
            className="w-full px-2 py-1 border border-gray-300 rounded-sm rounded outline-none "
            placeholder="Confirm Password"
            id="confirmPassword"
            type="password"
            value={passwordState.confirmPassword}
            onChange={handleChange}
            required
          />
        </label>

        <button
          type="submit"
          className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2  my-3 rounded rounded-md self-end"
        >
          Change Password
        </button>
      </form>
    );
}

export default ChangePassword


