import React, {useState } from "react";
import Input from "../../components/Input";
import toast from "react-hot-toast";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { AuthService } from "../../services/AuthService";

const PasswordResetForm = () => {
  let [params] = useSearchParams();
  let email = params.get("email");
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  let history = useNavigate();
  // handler for changing form values
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState({
      ...state,
      [id]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // dummy login promise
    const toastId = toast.loading("User verification in progress");
    const t = AuthService.RequestPasswordReset(state.email)
      .then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          toast.success(res.message);
          history(`/password-reset-sent?email=${state.email}`);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.error);
      });
  };
  return (
    <div className="h-screen w-screen p-6 flex flex-col space-y-5 justify-center items-center">
      <form
        className="w-full  md:w-[30rem] flex flex-col space-y-5"
        onSubmit={handleSubmit}
      >
        <h1 className="font-bold text-left text-gray-800 text-2xl md:text-3xl mb-6">
          Reset Password
        </h1>
        <label className="block">
          <span className="block text-md font-medium text-gray-700 mb-3">
            Username/Email
          </span>
          <Input
            placeholder="Enter Username or Email Address"
            id="email"
            type="text"
            value={state.email}
            onChange={handleChange}
            required
          />
        </label>

        <button
          type="submit"
          className="w-full bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md"
        >
          Request Password Reset
        </button>
        <p>
   
              <Link
                to="/login"
                style={{
                  textDecoration: 'none',
                  color: 'green',
                  marginLeft: '5px',
                }}
              >
                Return to the Sign In page
              </Link>
            </p>
      </form>
    </div>
  );
};

export default PasswordResetForm;
