import React, { useState,useEffect } from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import Select from "../../../Select";
import { testConcessions } from "../../../../utils";
import GoBack from "../../sub-components/GoBack";
import TextArea from "../../../TextArea";
import { StockService } from "../../../../services/StockService";
import { Link, useNavigate } from "react-router-dom";
const AddCompartment = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    compartmentName: "",
    concession: null,
    description: "",
    numberOfTrees: "",
    compartmentSize: "",
  });
const [concessions, setConcessions] = useState([]);

  const loadConcessionList = () => {
    StockService.GetAllConcessions().then((res) => {
      let data = res.map((country)=>{
        country.value = country.concessionId;
        country.label = country.concessionName;
        return country;
     })
         setConcessions(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    loadConcessionList();
  }, []);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

 const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Adding new Compactment ");
     const addCompactmentPromise = StockService.AddCompactment(
       state.concession,
       state.compartmentName,
       state.description,
       state.numberOfTrees,
       state.totalNumberOfTrees,
       state.compartmentSize
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         history("/dashboard/stock-management/compactment");
         
       } else {
         toast.error(res.message);
       }
     });
   
  };
  return (
    <>
     <GoBack />
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
     
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        New Compartment
      </h2>
        <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Compartment Name
          </span>
          <Input
            id="compartmentName"
            placeholder="Compartment Name"
            type="text"
            value={state.compartmentName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Compartment Size
          </span>
          <Input
            id="compartmentSize"
            placeholder="Compartment Size"
            type="text"
            value={state.compartmentSize}
            onChange={handleChange}
            required
          />
        </label>
        
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Number of Trees
          </span>
          <Input
            id="numberOfTrees"
            placeholder="Total Number of Trees"
            type="text"
            value={state.numberOfTrees}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Select Concession
          </span>
          <Select
            id="concession"
            type="text"
            options={concessions}
            value={state.concession}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
            id="description"
            placeholder="Description"
            type="text"
            value={state.description}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Compartment
      </button>
    </form>
    </>
    
  );
};

export default AddCompartment;
