import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router";
// import { testUsers } from "../../../utils";
import UserCard from "./sub-components/UserCard";
import { UserService } from "../../../services/UserService";
import { StockService } from "../../../services/StockService";
import UserContext from "../../../context/UserContext";
import ReactPaginate from 'react-paginate';
  // console.log({ location });
  function Items(currentItems) {
    const itemList =  Object.values(currentItems)
    const navigate=useNavigate()
    const editAddress= addressId =>()=>{
      navigate(`/admin/order-details/${addressId}`);
    }
    return (
      <>
        <div className="grid w-full grid-rows-auto grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-4">
        {itemList && itemList.length > 0 ? (
          
          itemList.map((user) => (
            <UserCard key={user.orderId} {...user} />
          ))
        
        ) : (
          <div className="flex ">
          <div className="px-1 text-gray-500 justify-self-center">
            No Items Found !
          </div>
          
        </div>
        )}
      </div>
      </>
    );
  }
const ActiveOrders = () => {
  const user = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const loadUsers = () => {
    StockService.GetOrders(user.userData.userId).then((res) => {
      // console.log(res)
      let x = res.filter((user) => user.orderStatus.orderStatusName === 'InProgress')
      setOrders(...orders, x);
      // console.log(orders);
    });
  };

   useEffect(() => {
 
       loadUsers();
     

   }, []);


  const itemsPerPage  = 4;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(orders.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(orders.length / itemsPerPage));
  }, [itemOffset, itemsPerPage,orders]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % orders.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
     

        <Items {...currentItems} />
    
       
  

    
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          pageClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          pageLinkClassName="page-item"
          previousClassName="page-item"
          previousLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 focus:shadow-none"
          nextClassName="page-item"
          nextLinkClassName="page-link relative block py-1.5 px-3 rounded border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
        
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName=" flex justify-center mx-auto mt-10"
          activeClassName="active bg-lime-600 text-white"
        />
   
    
    {/* <div className="order-status-card">
   
   
    </div> */}
  </>
  );
};

export default ActiveOrders;
