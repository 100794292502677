import React, { useContext, useEffect, useState } from "react";
import Input from "./Input";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { UserService } from "../services/UserService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UserContext from "../context/UserContext";
const Signup = () => {
  let user = useContext(UserContext);
  const [userState] = useState(user);
  //  const dispatch = useAppDispatch();
  let history = useNavigate();
  useEffect(() => {
    if (userState.authenticated) {
      history('/');
    }
  });

  const SignupSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    phoneNumber: yup.string().required().min(10).max(10),
    clientTypeId: yup.string().required(),
    password: yup.string().required(),
    passwordRepeat: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = (data) => {
    // console.log(data)
    const toastId = toast.loading('Registration in progress');
    const t = UserService.RegisterUser(
      data.username,
      data.password,
      data.firstName,
      data.lastName,
      data.clientTypeId,
      data.phoneNumber,
      data.email
    )
      .then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          toast.success(res.message);
          setTimeout(() => history('/login'), 3000);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.error);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full  md:w-[30rem] flex flex-col space-y-5">
    <h1 className="mb-0 text-2xl font-bold text-left text-lime-700 md:text-3xl">
        Sign Up
      </h1>
      <p className="mb-6 text-xl text-left text-gray-400 md:text-xl">Create account to place an order</p>
    <div className="grid grid-cols-2 gap-4">
      <div className="mb-1 form-group">
          <input
            type="text"
            {...register('firstName')}
            placeholder="Enter First Name"
        
            className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none "}

          />
         
        </div>
        <div className="mb-1 form-group">
         
          <input
            type="text"
            {...register('lastName')}
            placeholder="Enter Last Name"
            className={errors.lastName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none "}
          />
        </div>
    </div>
    
    <div className="grid grid-cols-2 gap-4">
      <div className="mb-1 form-group">
      <input
        type="text"
        {...register('username')}
        placeholder="Enter Username"
        className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full rounded-sm border border-gray-300 py-3 px-4 outline-none "}
      />
         
        </div>
        <div className="mb-1 form-group">
         
        <input
        type="text"
        {...register('phoneNumber')}
        placeholder="Phone Number"
        className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full rounded-sm border border-gray-300 py-3 px-4 outline-none "}
      />
        </div>
    </div>



    <div className="grid grid-cols-2 gap-4">
      <div className="mb-1 form-group">
          <input
            type="password"
            {...register('password')}
            placeholder="Create Password"
            className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none "}
          />
         
        </div>
        <div className="mb-1 form-group">
         
            <input
            type="password"
            {...register('passwordRepeat')}
            placeholder="Repeat Password"
            className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none "}
          />
        </div>
    </div>
   
    <div style={{ display: 'flex',marginBottom:'0.25rem' }}>
      <input
        type="text"
        {...register('email')}
        placeholder="Enter Your Email"
        className={errors.firstName? "input-error block w-full  rounded-sm border border-gray-300 py-3 px-4 outline-none":"block w-full rounded-sm border border-gray-300 py-3 px-4 outline-none "}
      />
    </div>
    <div

      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px',
       
      }}
    >
      <label className="block w-full mb-2">Select User Type</label>
      <select {...register('clientTypeId')} 
        className="block w-full px-3 py-4 pr-4 border border-gray-300 rounded-sm outline-none "
      >
        <option value="1">Personal Account</option>
        <option value="2">Corporate Account</option>
      </select>
    </div>
    <button
        type="submit"
        className="w-full bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded "
      >
        SIGN UP
      </button>
      <p>
      Already have an account?
              <Link
                to="/login"
                style={{
                  textDecoration: 'none',
                  color: 'green',
                  marginLeft: '5px',
                }}
              >
                Sign In
              </Link>
            </p>
  </form>
    
  );
};

export default Signup;
