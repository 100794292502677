import React, { useState, useEffect,useContext } from "react";
import AnimatedNumber from "animated-number-react";
import DataTable from "react-data-table-component";
import { UserService } from "../../services/UserService";
import { StockService } from "../../services/StockService";
import UserContext from "../../context/UserContext";
import TableActions from "./StockManagement/sub-components/TableActions";
import { useNavigate } from "react-router-dom";
import { ReportService } from "../../services/ReportService";
const DashboardMain = () => {
  const user = useContext(UserContext);
  const navigate=useNavigate()
  const [state, setState] = useState({
    totalCount: 0,
    totalDelivered: 0,
    totalInProgress: 0,
    totalInTransit: 0,
    totalNew: 0
  });


const loadOrderCount = () => {
  ReportService.GetOrderCounts(user.userData.userId).then((res) => {
    console.log(res);
    // setUserProfile(true);
    setState({
      ...state,
      totalCount: res.totalCount,
      totalNew: res.totalNew,
      totalInProgress: res.totalInProgress,
      totalInTransit:res.totalInTransit,
      totalDelivered: res.totalDelivered

    });
    console.log(state);
  });
};
useEffect(() => {
 
    loadOrderCount();
  
}, []);



const handleGetOrders = () => {
  StockService.GetOrders(user.userData.userId).then((res) => {
    // let data = Array.from(res);
    setOrders(res);
    setPending(false);
    //  console.log(countries)
  });
};
const [orders, setOrders] = useState([ {
  id: 1,
  species: '',
  volume: 0,
  contractNo: '',
  unitPrice: 0,
  totalAmount: 0,
  dateCreated: '',
  status: '',
  productType: '',
  thickness: 0,
  width: 0,
  length: '',
  treatment: 'kiln dry',
  name: '',
  address: '',
  country: '',
  city: '',
  countryCode: '00233',
  gpsAddress: 'GA-059-3733',
  orderNumber:'',
  numberOfProducts:0,
  grossAmount:0,
  vatAmount:0,
  orderStatus:{},
  destinationCountry:{},
  deliveryAddress:{}
}]);
useEffect(() => {
  handleGetOrders();
}, []);
  const columns = [
    {
      name: "Order Number",
      selector: (row) => row.orderNumber,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "Country",
      selector: (row) => row.destinationCountry.countryName,
    },
    {
      name: "Delivery Address",
      selector: (row) => row.deliveryAddress?.location||'',
    },
    {
      name: "Vat",
      selector: (row) => row.vatAmount,
    },
    {
      name: "Gross Amount",
      selector: (row) => row.grossAmount,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
    },
    {
      name: "Number of Items",
      selector: (row) => row.numberOfProducts,
    },
    {
      name: "Date",
      selector: (row) => row.dateCreated,
    },
    {
      name:'Action',
      selector:(row)=><div>
        <TableActions viewAction={()=>navigate(`/dashboard/my-orders/${row.orderId}`)} noDelete/>
      </div>
    }
  ];

  const [pending, setPending] = useState(true);

  return (
    <div className="flex flex-col items-start w-full h-full">
      <h1 className="my-3 mb-6 text-4xl font-extrabold text-lime-700">
        Dashboard
      </h1>
      <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5">
        <LabelAndValue
          label={"Total Orders"}
          value={state.totalCount}
          className="text-gray-500/75"
        />
         <LabelAndValue
          label={"Total New"}
          value={state.totalNew}
          className="text-blue-500/75"
          active
        />
        <LabelAndValue
          label={"Total In Progress"}
          value={state.totalInProgress}
          className="text-green-500/75"
          active
        />
        <LabelAndValue
          label={"Total In Transit"}
          value={state.totalInTransit}
          className="text-violet-500/75"
        />
        <LabelAndValue
          label={"Total Delivered"}
          value={state.totalDelivered}
          className="text-red-500/75"
        />
        
      </div>
      <div className="w-full mt-5">
        <h3 className="my-3 mb-6 text-xl font-medium text-gray-500">
          Order History
        </h3>
        <DataTable className="w-full"  progressPending={pending}
        pagination columns={columns} data={orders} />
      </div>
    </div>
  );
};

export default DashboardMain;

export const LabelAndValue = (props) => {
  const { label, value, className } = props;
  const formatValue = (value) => value.toFixed(0);
  return (
    <div
      className={`bg-white shadow-sm min-h-[5vh] rounded rounded-lg p-6 flex flex-col space-y-4 hover:shadow-md transition ease-in-out delay-150 `}
    >
      <span className="text-lg font-medium text-gray-300 uppercase">
        {label}
      </span>
      <AnimatedNumber
        value={value}
        className={`text-5xl font-bold  ${className}`}
        formatValue={formatValue}
        duration={2000}
      />
     
    </div>
  );
};
