import React, { useState } from "react";
import Input from "../../../Input";
import toast from "react-hot-toast";
import { FormGrid } from "../../Users/AddANewUser";
import TextArea from "../../../TextArea";
import GoBack from '../../sub-components/GoBack'
import DocumentUpload from "../../sub-components/DocumentUploads";
import { StockService } from "../../../../services/StockService";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
const AddConcession = () => {
  let history = useNavigate();
  const [state, setState] = useState({
    concessionName: "",
    concessionSize: "",
    description: "",
    totalNumberOfTrees: "",
    address: "",
    permitNumber: "",
    permitExpiryDate: "",
    permitDocument: "",
    geoLocation: "",
    registrationDate: moment().format('l')
  });

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(state)
    const toastId = toast.loading("Adding new Concession ");
    StockService.AddConcession(
      state.concessionName,
      state.concessionSize,
      state.description,
      state.registrationDate,
      state.totalNumberOfTrees,
      state.address,
      state.geoLocation,
      state.permitNumber,
      state.permitExpiryDate,
      state.permitDocument
    ).then((res) => {
      toast.dismiss(toastId);
      if (res.Successful) {
        toast.success(res.message);
        history("/dashboard/stock-management/concession");
      } else {
        toast.error(res.message);
      }
    });
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <GoBack/>
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        New Concession 
      </h2>
       <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Concession Name
          </span>
          <Input
            id="concessionName"
            placeholder="Concession Name"
            type="text"
            value={state.concessionName}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Concession Size
          </span>
          <Input
            id="concessionSize"
            placeholder="Concession Size"
            type="text"
            value={state.concessionSize}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          
           <TextArea
            placeholder="description"
            type="text"
            value={state.description}
           
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Total Number of Trees
          </span>
          <Input
            id="totalNumberOfTrees"
            placeholder="Total Number of Trees"
            type="text"
            value={state.totalNumberOfTrees}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Address</span>
          <TextArea
            id="address"
            placeholder="eg: 0505707987"
            type="text"
            value={state.address}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Geolocation</span>
          <Input
            id="geoLocation"
            placeholder="Geo Location"
            type="text"
            value={state.geoLocation}
            onChange={handleChange}
          />
        </label>
      </FormGrid>
      <h2 className=" text-xl font-medium text-gray-700 my-4">Permit Info</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Permit Number
          </span>
          <Input
            id="permitNumber"
            placeholder="Permit Number"
            type="text"
            value={state.permitNumber}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Permit Registration Date
          </span>
          <Input
            id="registrationDate"
            placeholder="Permit Expiry Date"
            type="date"
            value={state.registrationDate}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Permit Expiry Date
          </span>
          <Input
            id="permitExpiryDate"
            placeholder="Permit Expiry Date"
            type="date"
            value={state.permitExpiryDate}
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      <span className="block text-md  text-gray-700 mb-3 mt-4">
        Permit Document
      </span>
      <DocumentUpload state={state} />
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Concession
      </button>
    </form>
  );
};

export default AddConcession;
