import React, { useState, useEffect,useContext } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { UserService } from "../../../services/UserService";
import { FormGrid } from "./AddNewAddress";
import SelectCountry from "../../SelectCountry";
import { StockService } from "../../../services/StockService";
import { RiAddLine, RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import {  useNavigate } from "react-router-dom";
import GoBack from "../sub-components/GoBack";
import Select, { OptionProps } from 'react-select';
const EditAddress = () => {
  const params = useParams();
  let history = useNavigate();
  const [addressState, setAddressState] = useState({
  location: "",
  gpsAddress: "",
  postalCode: "",
  city: "",
  country: "",
  countryId:0
});
const [selectedOption, setSelectedOption] = useState(null);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    handleGetCountry();
  }, []);
  useEffect(() => {
      handleGetAddress();
  }, []);

  useEffect(() => {
    
    let y = countries.filter((x)=> x.value === addressState.countryId)
    
    setSelectedOption(y[0])
}, [addressState,countries]);


  const handleGetCountry = () => {
    UserService.GetCountries().then((res) => {
      // let data = Array.from(res);
      let test = res.map((country)=>{
         country.value = country.countryId;
         country.label = country.countryName;
         return country;
      })

      setCountries(test);
     
      //  console.log(countries)
    });
  };
  let addressId = 0;
  if (params && params.id) {
      addressId = parseInt(params.id)
  }
  const handleGetAddress = () => {
      
     
      StockService.GetDeliveryAddressById(addressId).then((res) => {
          setAddressState({
              ...addressState,
              location: res.location || "",
              gpsAddress: res.gpsAddress || "",
              postalCode: res.postalCode || "",
              city: res.city || "",
              countryId: res.countryId,
          });
          
          //  console.log(state)
      });
  };
const handleChange = (e) => {
  const { value, id } = e.target;
  setAddressState((oldPasswordState) => ({
    ...oldPasswordState,
    [id]: value,
  }));
};

const handleCountryChange =(e)=> {
  console.log(e)
  setSelectedOption(e);
  addressState.countryId = e.value
  console.log(`Option selected:`, selectedOption);
};

const handleSubmit = (e) => {
  e.preventDefault();
  console.log(selectedOption)
  const toastId = toast.loading('Updating Delivery Address');
  
  const UpdateAddressPromise = StockService.UpdateDeliveryAddress(
      addressId,
    addressState.location,
    addressState.gpsAddress,
    addressState.city,
    addressState.postalCode,
    addressState.countryId,
    
    
  ).then((res) => {
    toast.dismiss(toastId);
    if (res.Successful) {
      toast.success(res.message);
        // history('/dashboard/delivery-address')
    } else {
      toast.error(res.message);
    }
  });
  
};

  const [disabled, setDisabled] = useState(true);
 
  const toggleDisable = () => setDisabled((oldState) => !oldState);
  return (
    <>
      <div className="flex items-center justify-between w-full">
        <h1 className="my-3 text-4xl font-extrabold text-lime-700">Delivery Address</h1>
        
      </div>
      <GoBack />
      <div className="flex self-end justify-end space-x-2">
        {disabled ? (
          <div className="">
            <button
              onClick={toggleDisable}
              type="button"
              className="p-2 bg-gray-700 rounded rounded-full shadow-lg md:hidden hover:bg-gray-800 "
            >
              <RiEditBoxLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white rounded rounded-md shadow-lg bg-lime-700 md:block hover:bg-lime-600 text-medium"
            >
              Edit
            </button>
          </div>
        ) : (
          <div>
            <button
              type="button"
              onClick={toggleDisable}
              className="md:hidden rounded rounded-full p-1.5 shadow-lg bg-red-700 hover:bg-red-800 "
            >
              <RiCloseLine className="text-lg text-white" />
            </button>
            <button
              type="button"
              onClick={toggleDisable}
              className="hidden px-4 py-2 font-semibold text-white bg-red-700 rounded rounded-md shadow-lg md:block hover:bg-red-800 text-medium"
            >
              Cancel
            </button>
          </div>
        )}
       {/* {state.isActive ? (
          <button  onClick={deactivateProduct} type='button' className="text-sm text-red-600 bg-red-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-red-600/50">
            - Deactivate
          </button>
        ) : (
          <button  onClick={activateProduct} type='button' className="text-sm text-green-600 bg-green-200 px-2 py-0.5 rounded rounded-lg hover:font-medium hover:ring-2 hover:ring-green-600/50">
            + Activate
          </button>
        )} */}
      </div>
      <h2 className="my-2 text-xl font-medium text-gray-700 ">
        {disabled ? "Delivery Address Details" : "Edit Address"}
      </h2>    
      <form className="flex flex-col w-full" onSubmit={handleSubmit}>
      {/* <h2 className="my-2 text-xl font-medium text-gray-700 ">Address Info</h2> */}
      <FormGrid>
      <label className="block">
          <span className="block mb-3 text-gray-700 text-md">GPS Address</span>
          <Input
            id="gpsAddress"
            value={addressState.gpsAddress}
            onChange={handleChange}
            
            
            type="text"
            placeholder="GPS Address"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Location</span>
          <Input
            placeholder="Location"
            type="text"
            id="location"
            onChange={handleChange}
            value={addressState.location}
            disabled={disabled}
          />
        </label>
        
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Country</span>
          {/* <SelectCountry
            id="country"
            type="text"
            options={countries}
            value={addressState.country}
            onChange={handleChange}
            required
            disabled={disabled}
          /> */}
          <Select  
                  options={countries}
                  value={selectedOption}
                  isDisabled={disabled}
                  defaultValue={selectedOption}
                  onChange={handleCountryChange}
                  menuPlacement="auto" menuPosition="fixed" placeholder="Select Country"/>
        </label>
        
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">City</span>
          <Input
            placeholder="City"
            type="text"
            id="city"
            onChange={handleChange}
            value={addressState.city}
            disabled={disabled}
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Postal Code</span>
          <Input
            placeholder="Postal Code"
            type="text"
            id="postalCode"
            onChange={handleChange}
            value={addressState.postalCode}
            disabled={disabled}
          />
        </label>
      </FormGrid>
      {/* <h2 className="my-4 text-xl font-medium text-gray-700 ">Signature</h2>
      <SignatureSection /> */}
        {!disabled && (
        <button
          type="submit"
          className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
        >
          Update Address
        </button>
      )}
    </form>
    </>
    
  );
};

export default EditAddress;
