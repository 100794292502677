import React, { useState, useEffect } from "react";
import Input from "../../Input";
import toast from "react-hot-toast";
import {
  ProfileImageSection,
  SignatureSection,
} from "../sub-components/DocumentUploads";
import { FormGrid } from "./AddANewUser";
// import { testUsers } from "../../../utils";
import { useParams } from "react-router-dom";
import { UserService } from "../../../services/UserService";
const EditUserProfile = () => {
  const [state, setState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: ""
  });

  const params = useParams();

  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const handleGetUser = () => {
    UserService.GetAdminById(params.id).then((res) => {
      setState({
        ...state,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        active: res.isActive,
        phoneNumber: res.phoneNumber,
      });
      //  console.log(state)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Editing User");
    UserService.UpdateUserInfo({
      userId: params.id,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phoneNumber: state.phoneNumber,
    })
      .then((res) => {
        toast.dismiss(toastId);
        console.log(res);
        if (res.Successful) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        toast.error(error.error);
      });
    // handles the toast loader for the login process
    // toast.promise(editUserPromise, {
    //   loading: "Editing User",
    //   success: "User Edited Successfully",
    //   error: "User Edit Failed",
    // });
    // editUserPromise.then((results) => console.log(results));
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <ProfileImageSection {...params} />
      <h2 className=" text-xl font-medium text-gray-700 my-2">
        Personal Details
      </h2>
      <FormGrid>
        {/* <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Username</span>
          <Input
            placeholder="Username"
            id="username"
            type="text"
            onChange={handleChange}
            value={state.username}
          />
        </label> */}
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">First Name</span>
          <Input
            placeholder="First Name"
            id="firstName"
            type="text"
            onChange={handleChange}
            value={state.firstName}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Last Name</span>
          <Input
            placeholder="Last Name"
            type="text"
            id="lastName"
            onChange={handleChange}
            value={state.lastName}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Email Address
          </span>
          <Input
            placeholder="Email Address"
            type="email"
            id="email"
            onChange={handleChange}
            value={state.email}
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Phone Number
          </span>
          <Input
            placeholder="eg: 0505707987"
            type="tel"
            id="phoneNumber"
            onChange={handleChange}
            value={state.phoneNumber}
          />
        </label>
      </FormGrid>
      
      <button
        type="submit"
        className="w-full md:w-auto md:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Edit User
      </button>
    </form>
  );
};

export default EditUserProfile;
