import React from "react";

const SelectCountry = (props) => {
//    console.log(props)
  return (
    <select
      className="w-full px-4 py-2 border border-gray-300 rounded-sm rounded outline-none disabled:bg-transparent disabled:border-none disabled:px-0 disabled:py-1 disabled:text-gray-400 "
      {...props}
    >
      <option disabled>Select A Country</option>
      {props.options &&
        props.options.map((item, index) => (
          <option key={index} value={item.countryId}>
            {item.countryName}
          </option>
        ))}
    </select>
  );
};

export default SelectCountry;
