import React, { useState,useEffect } from "react";
import GoBack from "../../sub-components/GoBack";
import { FormGrid } from "../../Users/AddANewUser";
import Input from "../../../Input";
import Select from "../../../Select";

import toast from "react-hot-toast";
import TextArea from "../../../TextArea";
import { StockService } from "../../../../services/StockService";
const AddProduct = () => {
  const [state, setState] = useState({
    productName: "",
    productType:"",
    isActive: false,
    description: "",
    quantityInStock: "",
    price: "",
  });

  const [productTypes,setProductTypes] = useState([])
  const productTypeList = () => {
    StockService.GetProductTypes().then((res) => {
        //  let data = Array.from(res);
         let data = res.map((country)=>{
          country.value = country.productTypeId;
          country.label = country.productTypeName;
          return country;
       })
         setProductTypes(data);
        //  console.log(concessions);
       
    });
  };
  useEffect(() => {
    productTypeList();
  }, []);
  const handleChange = (e) => {
    const { value, id } = e.target;
    setState((oldState) => ({ ...oldState, [id]: value }));
  };

 const handleSubmit = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Adding Product ");
    StockService.AddProduct(
       state.productType,
       state.productName,
       state.description,
       state.price,
       state.quantityInStock
     ).then((res) => {
       toast.dismiss(toastId);
       if (res.Successful) {
         toast.success(res.message);
         
       } else {
         toast.error(res.message);
       }
     });
   
  };
  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <GoBack />
      <h2 className=" text-xl font-medium text-gray-700 my-2">New Product</h2>
      <FormGrid>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Product Name
          </span>
          <Input
            placeholder="Product Name"
            type="text"
            value={state.productName}
            id="productName"
            onChange={handleChange}
            required
          />
        </label>

        
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Quantity In Stock
          </span>
          <Input
            placeholder="Quantity In Stock"
            type="text"
            value={state.quantityInStock}
             id="quantityInStock"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Price
          </span>
          <Input
            placeholder="Market Value"
            type="text"
            value={state.price}
             id="price"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">
            Product Type
          </span>
          <Select
            label='Select A Product Type'
            options={productTypes}
            value={state.productType}
             id="productType"
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <span className="block text-md  text-gray-700 mb-3">Description</span>
          <TextArea
            placeholder="Description"
            type="text"
            value={state.description}
             id="description"
            onChange={handleChange}
            required
          />
        </label>
      </FormGrid>
      {/* <div className='mt-3'>
        <input
          placeholder="Number of Trees Remaining"
          type="checkbox"
          id="isActive"
          value={state.isActive}
        />
        <span className="text-md  text-gray-700 ml-3">Active</span>
      </div> */}
      <button
        type="submit"
        className="w-full sm:w-auto sm:px-3 bg-[#3e7c17] hover:bg-[#356C14] text-white font-semibold py-2 lg:py-3 my-3 rounded rounded-md self-end"
      >
        Create Product
      </button>
    </form>
  );
};

export default AddProduct;
