import React, { useState, useEffect,useContext,useRef } from "react";
import { useNavigate,useParams } from "react-router";
import { UserService } from "../../../services/UserService";
import { StockService } from "../../../services/StockService";
import UserContext from "../../../context/UserContext";
import { BiTrashAlt } from "react-icons/bi";
import DataTable from "react-data-table-component";
import NumberFormat from 'react-number-format';
import Input from "../../Input";
import toast from "react-hot-toast";
import Select, { OptionProps } from 'react-select';
import StepZilla from "react-stepzilla";
import OrderTable from "./sub-components/OrderTable/orderTable";
import { AiOutlineCloudUpload } from "react-icons/ai";
import './editOrder.css';
const EditPlaceOrder = () => {
  const params = useParams();
  let history = useNavigate();
  const user = useContext(UserContext);
  const [order,setOrder] = useState({userId: 0, clientId: 0, description: '', numberOfProducts: 0, totalAmount: '', destinationCountryId: 0,comment:'',email:'',phoneNumber:'',vatAmount:0.0,deliveryAddressId:0,contractNumber:'',orderItems:[]});
  
  const [addressState, setAddressState] = useState({
    userId: user.userData.userId,
    clientId: user.userData.clientFullInfo.clientId,
    clientName: user.userData.firstName+' '+user.userData.lastName,
    description: "",
    numberOfProducts: 0,
    totalAmount: 0,
    comment: "",
    email: "",
    phoneNumber: "",
    vatAmount: 0,
    deliveryAddressId: 0,
    location: "",
    gpsAddress: "",
    postalCode: "",
    city: "",
    country: "",
    countryId:0,
    contractNumber:'',
    orderItems:{},
    orderId:0,
    orderNumber:''
  });
  const tableDataInit = {
    productType:''
,    product:'',
    species:'',
    thickness:0,
    width:0,
    length:0,
    treatment:'',
    quantity:0,
    price:0.00,
    productId: 0,
    speciesId: 0,
    amount: 0,
    tickness: 0,
    treatmentTypeId: 0,
    productTypeId: 0,
  }
  const [rowsData, setRowsData] = useState([tableDataInit]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);



  const handleChange = (e) => {
    const { value, id } = e.target;
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      [id]: value,
    }));

    
  };


  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAddressOption, setSelectedAddressOption] = useState(null);
  const handleCountryChange =(e)=> {
    setSelectedOption(e);
    addressState.countryId = e.value
   
  };

  const handleAddressChange =(e)=> {
    setSelectedAddressOption(e);
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      ["deliveryAddressId"]: e
    }));
    let count = countries.find(c=>c.countryId === e.countryId);
    setSelectedOption(count);
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      ["countryId"]: count
    }));
  };
  const handleGetCountry = () => {
    UserService.GetCountries().then((res) => {
      let test = res.map((country)=>{
          country.value = country.countryId;
          country.label = country.countryName;
          return country;
      })
      setCountries(test);
    
    });
  };
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    handleGetCountry();
  }, []);
  const [delieveryAddress, setDeliveryAddress] = useState([]);
  useEffect(() => {
    handleGetAddress();
  }, []);
 
  const handleGetAddress = () => {
    StockService.GetDeliveryAddresses(parseInt(user.userData.userId)).then((res) => {
      let test = res.map((delieveryAdd)=>{
          delieveryAdd.value = delieveryAdd.deliveryAddressId;
          delieveryAdd.label = delieveryAdd.location;
          return delieveryAdd;
      })
      setDeliveryAddress(test);
    
    });
  };

  const handleLogoChange = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);;
      setFilename(e.target.files[0].name);
      
    }
  };

  const uploadLogo = (e,docName) => {
    const toastId = toast.loading('Please wait File upload in progress');
    UserService.SaveCompanyDocuments(e.target.files[0],docName, user.userData.userId, (event) => {})
      .then((res) => {
         toast.dismiss(toastId);
        if (res && res.isSuccessful) {
          toast.success("Upload Successful");
        } else {
          toast.error(
            "Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
      
      });
  };
  const [selectedFile, setSelectedFile] = useState();
  const [progress, setProgress] = useState(0);
  const [filename, setFilename] = useState("Contract Document");
  const handleDelete = () => {
    setSelectedFile(null);
    setProgress(0);
  };

  const handleCommentChange =(evnt)=>{
    const { value, id } = evnt.target;
    setAddressState((oldPasswordState) => ({
      ...oldPasswordState,
      [id]: value,
    }));
     
  }

  
  const saveNewOrder = ()=>{
    
    let oldItems = rowsData.filter(x=>x.orderItemId);
    let newItems = rowsData.filter(x=>!x.orderItemId);
    const postData = {
      orderId:addressState.orderId,
      orderNumber:addressState.orderNumber,
      userId: user.userData.userId, 
      clientId: user.userData.clientFullInfo.clientId,
      description: addressState.comment,
      numberOfProducts: totalItems,
      totalAmount: parseFloat(totalAmount)*1.125,
      destinationCountryId: addressState.destinationCountry.countryId,
      comment:addressState.comment,
      email:addressState.email,
      phoneNumber:addressState.phoneNumber,
      vatAmount:parseFloat(totalAmount)*0.125,
      grossAmount:parseFloat(totalAmount),
      deliveryAddressId:addressState.deliveryAddressId.deliveryAddressId,
      contractNumber:addressState.contractNumber,
      orderItems:oldItems,
      newOrderItems:newItems
    }
   
    console.log(postData)
    // e.preventDefault();
    const toastId = toast.loading('Updating Order ...');
    StockService.UpdateOrder(postData).then((res) => {
        toast.dismiss(toastId);
        if (res.Successful) {
          toast.success(res.message);
          // history('/admin/order-history');
        } else {
          toast.error(res.message);
        }
      }).catch((error) => {
        toast.error(error.error);
      });
  }
  const steps =
    [
      {name: 'Contact & Delivery Info', component: <Step1 
      addressState={addressState} 
      setAddressState={setAddressState}
      countries = {countries}
      handleChange ={handleChange}
      handleAddressChange ={handleAddressChange}
      delieveryAddress={delieveryAddress}
      selectedOption={selectedOption}
      selectedAddressOption={selectedAddressOption}
      handleDelete={handleDelete}
      filename={filename}
      setFilename={setFilename}
      setSelectedFile={setSelectedFile}
      selectedFile={selectedFile}

     

      />},
      {name: 'Choose Products', component: <Step2  
      addressState={addressState} 
      setAddressState={setAddressState}
      rowsData={rowsData} 
      setRowsData={setRowsData}
      totalItems={totalItems} 
      setTotalItems={setTotalItems}
      totalAmount={totalAmount}
      setTotalAmount={setTotalAmount}
      handleCommentChange={handleCommentChange}

      
      />},

      {name: 'Order Summary', component: <Step3
        addressState={addressState} 
        setAddressState={setAddressState}
        rowsData={rowsData} 
        setRowsData={setRowsData}
        totalItems={totalItems} 
        setTotalItems={setTotalItems}
        totalAmount={totalAmount}
        setTotalAmount={setTotalAmount}
        saveNewOrder={saveNewOrder}
      />},
      // {name: 'Step 4', component: <Step4 />},
      // {name: 'Step 5', component: <Step5 />}
    ]
  
    const loadOrderDetails = () => {
      StockService.GetOrderDetail(parseInt(params.id)).then((res) => {
        console.log(res.deliveryAddress)
        
        
        setTotalAmount(res.totalAmount)
        setTotalItems(res.orderItems.length)
        setAddressState(res)
        let test = res.orderItems.map((orderIt)=>{
            console.log(orderIt)
            orderIt.productType.value = orderIt.productType.productTypeId;
            orderIt.productType.label = orderIt.productType.productTypeName;
            orderIt.product.value = orderIt.product.productId;
            orderIt.product.label = orderIt.product.productName;
            orderIt.species.value = orderIt.species.speciesId;
            orderIt.species.label = orderIt.species.speciesName;
            if(orderIt.treatmentType){
              orderIt.treatmentType.value = orderIt.treatmentType.treatmentTypeId;
              orderIt.treatmentType.label = orderIt.treatmentType.treatmentTypeName;
            }
          
            return orderIt;
        })
        setRowsData(test)
        // let count2 = delieveryAddress.find(c=>c.deliveryAddressId === res.deliveryAddress.deliveryAddressId);
        // setSelectedAddressOption(count2);
        // setSelectedOption(res.destinationCountry)
        // setDeliveryAddress(res.deliveryAddress)
        
      });
    };
  
     useEffect(() => {
      
           loadOrderDetails();
         
     }, []);
  
     useEffect(() => {
       if(addressState.deliveryAddress){
        let count2 = delieveryAddress.find(c=>c.deliveryAddressId === addressState.deliveryAddress.deliveryAddressId);
        setSelectedAddressOption(count2);
       }
      
      }, [delieveryAddress,addressState]);
    useEffect(() => {
      let count = countries.find(c=>c.countryId === addressState.destinationCountry.countryId);
      setSelectedOption(count);
      }, [selectedAddressOption,countries]);
  return (
    <>
      <div className="flex w-full">
        <h1 className="my-3 text-4xl font-extrabold text-lime-700">EDIT ORDER</h1>
        
      </div>
      
      <div className="">
        <StepZilla className="flex w-full" steps={steps} preventEnterSubmission={true}/>
      </div>
   
  </>
  );
};
const Step1 =(props)=>{
  
  const user = useContext(UserContext);
  
  const [preview, setPreview] = useState("");
 

  const fileRef = useRef();
  
  const triggerLogoModal = () => {
    fileRef.current.click();
  };
  const [filename, setFilename] = useState(props.filename);
  const handleLogoChange = (e) => {
    e.preventDefault();
 
    if (e.target.files[0]) { 
      setFilename(e.target.files[0].name);
      props.setFilename(e.target.files[0].name);
      props.setSelectedFile(e.target.files[0]);
      
      // uploadLogo(e,'logo');
    }
  };
 
 

  let c = (e)=>{props.handleLogoChange(e)}


  return (
    <>
      <div className="w-full py-2 text-lime-700">
        <span className="place-order-txt">Delivery Contact Information</span>
      
      </div>
    
    
    <form className="w-full " >
      <FormGrid>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Email</span>
          <Input
            id="email"
            value={props.addressState.email}
            onChange={props.handleChange}
             type="text"  placeholder="Email Address"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Contact Number</span>
          <Input
            id="phoneNumber"
            value={props.addressState.phoneNumber}
            onChange={(e)=>(props.handleChange(e))}
             type="text" placeholder="Phone Number"
          />
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">Contract Number</span>
          <Input
            id="contractNumber"
            value={props.addressState.contractNumber}
            onChange={props.handleChange}
             type="text" placeholder="Contract Number"
          />
        </label>
        
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Country
          </span>
          <Select  
                 options={props.countries}
                 value={props.selectedOption}
                 isDisabled={true}
                 defaultValue={props.selectedOption}
                 onChange={props.handleCountryChange}
                 
                 menuPlacement="auto" menuPosition="fixed" placeholder="Destination Country"/>
        </label>
        <label className="block">
          <span className="block mb-3 text-gray-700 text-md">
            Delivery Address
          </span>
          <Select 
        options={props.delieveryAddress}
        value={props.selectedAddressOption}
        
        defaultValue={props.selectedAddressOption}
        onChange={props.handleAddressChange}
        menuPlacement="auto" menuPosition="fixed" placeholder="Select Delivery Address"/>
        </label>
      </FormGrid>
    
     </form>

     </>
  );
}
const Step2 =(props)=>{
  return (
    <OrderTable {...props} />
  );
}
const Step3 =(props)=>{
  const user = useContext(UserContext);
  const [addressState,setAddressState] = useState(props)
  const [rowsData, setRowsData] = useState(props.rowsData);
  const [totalItems, setTotalItems] = useState(props.totalItems);
  const [totalAmount, setTotalAmount] = useState(props.totalAmount);
  const productColumns = [

    {
      name: "Product Type",
      selector: (row) => row.productType.productTypeName,
    },
    {
      name: "Product",
      selector: (row) => row.product.productName,
      grow: 2
    },
    {
      name: "Species",
      selector: (row) => row.species.speciesName,
    },
    {
      name: "Treatment",
      selector: (row) => row.treatmentType?row.treatmentType.treatmentTypeName:"",
    },
    {
      name: "Thickness",
      selector: (row) => row.thickness||row.tickness,
    },
    {
      name: "Width",
      selector: (row) => row.width,
    },
    {
      name: "Length",
      selector: (row) => row.length,
    },
   
    {
      name: "Quantity",
      selector: (row) => row.quantity,
    }, {
      name: "Price",
      selector: (row) => `GHS ${row.price}.00`,
    }
   
  
  ];

  const [pending, setPending] = useState(false);
  const [products,setProducts] = useState([])
  return (
    <>
      <div className="w-full py-2 text-lime-700">
        <span className="place-order-txt">Order Summary</span>
      
      </div>

      <div className="w-full ">
          <div style={{display: 'flex',marginTop: '20px', marginBottom: '20px',}}>
          <table id="" className='w-full px-1 text-left text-gray-500 table-auto' >
            <thead >
              <tr>
                <th>Customer</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Delivery Address</th>
                <th>Country</th>
                <th>Contract Number</th>
                
              </tr>
            </thead>
            <tbody>  
              <tr >
                <td>
                  {user.userData.firstName} {user.userData.lastName}
                </td>
                <td>
                  {props.addressState.email}
                </td>
                <td>
                {props.addressState.phoneNumber}
                </td>
                <td>
                {props.addressState.deliveryAddress?.location}
                </td>
                <td>
                {props.addressState.destinationCountry.countryName}
                </td>
                <td>
                {props.addressState.contractNumber}
                </td>
              </tr>
            </tbody>
          </table>
          </div>

          
      </div>
      <DataTable
        className="w-full rounded rounded-lg"
        columns={productColumns}
        data={rowsData}
        progressPending={pending}
        pagination
      />
      <div className="flex w-full mt-2">
        <textarea rows={4} id="comment" className="w-full px-2 mb-2 text-gray-500" value={props.addressState.comment} placeholder="Additional Comments" onChange={(e)=>{e.preventDefault();}} />
        
      </div>
      {!props.addressState.destinationCountry.shortName === 'GH' && (
        <div className="px-4 summary">
          <div className="no-items">
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{totalItems}</span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount*0.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount * 1.125}
              prefix="$ "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
        {props.addressState.destinationCountry.shortName === 'GH' && (
        <div className="summary">
          <div className="no-items">
            <span>Number of items</span>
            <span style={{ marginLeft: '5px' }}>{totalItems}</span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>VAT (12.5%)</span>
            <span>
              {/* $ {totalAmount*0.125 } */}
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount*0.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Gross Total</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
          <div className="sum-underline"></div>
          <div className="sum-amt">
            <span>Total Amount</span>
            <span>
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={props.totalAmount * 1.125}
              prefix="GHS "
              decimalSeparator="."
              displayType="text"
              type="text"
              thousandSeparator={true}
              allowNegative={false} />
            </span>
            {/* <span>$ {totalAmount }</span> */}
          </div>
        </div>
        )}
     
      <div className="flex flex-col mt-2 place-items-end sum-submit">
        <input
          onClick={props.saveNewOrder}
          type="submit"
          value="SUBMIT"
          className="px-4 py-2 text-white cursor-pointer bg-lime-700"
        />
      </div>
    </>
  );
}

export const FormGrid = ({ children }) => {
  return (

     
      <div className="grid w-full grid-cols-1 gap-4 mb-2 sm:w-4/6 sm:grid-cols-2">
      {children}
    </div>
  
    
  );
};

export default EditPlaceOrder;
