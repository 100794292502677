import React, { useState, useRef, useEffect } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";

import { BiTrashAlt } from "react-icons/bi";
import { UserService } from "../../../services/UserService";
import UserContext from "../../../context/UserContext";
import toast from "react-hot-toast";
// import { Document } from "react-pdf/dist/esm/entry.webpack";
// The Profile Image component
export const ProfileImageSection = (props) => {
  const { onChange, ...rest } = props;

  // console.log(rest);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const fileRef = useRef();
  const triggerFileModal = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      // onChange(e.target.files[0]);
      // console.log(e.target.files[0]);
      test(e);
    }
  };
  const test = (e) => {
    UserService.SaveUserImage(e.target.files[0], rest.id)
      .then((res) => {
        if (res.isSuccessful) {
          toast.success("Profile Photo Uploaded");
          // this.userService.getUserInfoUpdate(userInfo._id);
        } else {
          toast.error(
            "Profile Photo Upload Failed, Delete file and Try again"
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const handleDelete = () => {
    setSelectedFile(null);
  };

  return (
    <div className="flex items-center justify-center w-full mb-6 sm:justify-start ">
      <input
        type="file"
        accept="image/*"
        className=""
        {...rest}
        onChange={handleChange}
        ref={fileRef}
        hidden
      />
      <div
        className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-full shadow-sm pointer"
        style={{ backgroundImage: `url(${preview})` }}
        onClick={triggerFileModal}
      >
        {!rest.disabled && (
          <AiOutlineCloudUpload className="text-2xl text-gray-600" />
        )}
      </div>

      {!rest.disabled && (
        <button
          onClick={handleDelete}
          type="button"
          className="ml-2 rounded rounded-md shadow-sm py-1.5 px-1 text-white bg-red-600 hover:bg-red-800"
        >
          <BiTrashAlt />
        </button>
      )}
    </div>
  );
};

// signature component
const DocumentUpload = (props) => {
  const { onChange, ...rest } = props;
  // console.log(rest);
  // console.log(props);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const [filename, setFilename] = useState("");
  const fileRef = useRef();
  const triggerFileModal = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    // console.log(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log(reader)
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFilename(selectedFile.name)
    } else {
      setPreview(null);
      setFilename('');
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    if (e.target.files[0]) {

      props.state.permitDocument = e.target.files[0];
      if(props.docname === "logo"){
        props.state.logo = e.target.files[0];
      }
      if(props.docname === "certificate_of_registration"){
        props.state.certificate_of_registration = e.target.files[0];
      }
      if(props.docname === "certificate_of_commencement"){
        props.state.certificate_of_commencement = e.target.files[0];
      }
      if(props.docname === "contract_doc"){
        props.state.contract_doc = e.target.files[0];
      }
     
     
      setSelectedFile(e.target.files[0]);
       
      // onChange(e.target.files[0]);
    }
  };
 
  const handleDelete = () => {
    setSelectedFile(null);
  };
  return (
    <div className="flex items-center justify-start w-full mb-6 ">
      <input
        type="file"
        className=""
        onChange={handleChange}
        ref={fileRef}
        {...rest}
        hidden
      />

      <div
        className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-lg shadow-sm pointer"
        style={{ backgroundImage: `url(${preview})` }}
        onClick={triggerFileModal}
      >
        <AiOutlineCloudUpload className="text-2xl text-gray-600" />
        <div> {filename}</div>
      </div>

      {!rest.disabled && (
        <button
          onClick={handleDelete}
          type="button"
          className="ml-2 rounded rounded-md shadow-sm py-1.5 px-1 text-white bg-red-600 hover:bg-red-800"
        >
          <BiTrashAlt />
        </button>
      )}
    </div>
  );
};

export default DocumentUpload;

// Signature Upload Document
export const SignatureSection = (props) => {
  const { onChange, ...rest } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const fileRef = useRef();
  const triggerFileModal = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    if (e.target.files[0]) {
       test(e);
      setSelectedFile(e.target.files[0]);
      // onChange(e.target.files[0]);
     
    }
  };
   const test = (e) => {
     UserService.SaveSignatureImage(e.target.files[0], rest.id)
       .then((res) => {
         if (res.isSuccessful) {
           toast.success("Signature Photo Uploaded");
           // this.userService.getUserInfoUpdate(userInfo._id);
         } else {
           toast.error(
             "Signature Photo Upload Failed, Delete file and Try again"
           );
         }
       })
       .catch((err) => {
        //  console.error(err);
       });
   };
  const handleDelete = () => {
    setSelectedFile(null);
  };
  return (
    <div className="flex items-center justify-start w-full mb-6 ">
      <input
        type="file"
        accept="image/*"
        className=""
        onChange={handleChange}
        ref={fileRef}
        {...rest}
        hidden
      />
      <div
        className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-lg shadow-sm pointer"
        style={{ backgroundImage: `url(${preview})` }}
        onClick={triggerFileModal}
      >
        {!rest.disabled && (
          <AiOutlineCloudUpload className="text-2xl text-gray-600" />
        )}
      </div>

      {!rest.disabled && (
        <button
          onClick={handleDelete}
          type="button"
          className="ml-2 rounded rounded-md shadow-sm py-1.5 px-1 text-white bg-red-600 hover:bg-red-800"
        >
          <BiTrashAlt />
        </button>
      )}
    </div>
  );
};

export const ContractUpload = (props) => {
  console.log(props);
  const { onChange, ...rest } = props;
   
  
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState("");
  const [filename, setFilename] = useState(props.filename);
  const fileRef = useRef();
  const triggerFileModal = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    // console.log(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log(reader)
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFilename(selectedFile.name)
    } else {
      setPreview(null);
      setFilename('');
    }
  }, [selectedFile]);

  const handleChange = (e) => {
    if (e.target.files[0]) {

      if(props.docname === "contract_doc"){
        props.state.contract_doc = e.target.files[0];
      }
      setSelectedFile(e.target.files[0]);
       
      // onChange(e.target.files[0]);
    }
  };
 
  const handleDelete = () => {
    setSelectedFile(null);
  };
  return (
    <div className="flex items-center justify-start w-full mb-6 ">
      <input
        type="file"
        className=""
        onChange={handleChange}
        ref={fileRef}
        {...rest}
        hidden
      />

      <div
        className="flex items-center self-center justify-center w-24 h-24 bg-white bg-center bg-no-repeat bg-cover border-2 border-gray-200 rounded rounded-lg shadow-sm pointer"
        style={{ backgroundImage: `url(${preview})` }}
        onClick={triggerFileModal}
      >
        <AiOutlineCloudUpload className="text-2xl text-gray-600" />
        <div> {filename}</div>
      </div>

      {!rest.disabled && (
        <button
          onClick={handleDelete}
          type="button"
          className="ml-2 rounded rounded-md shadow-sm py-1.5 px-1 text-white bg-red-600 hover:bg-red-800"
        >
          <BiTrashAlt />
        </button>
      )}
    </div>
  );
};

